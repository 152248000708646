/* eslint-disable eqeqeq */
import { Close } from "@mui/icons-material";
import { Box, IconButton, List, ListItem, ListItemText } from "@mui/material";
import { Stack } from "@mui/system";
import { Link, useLocation } from "react-router-dom";
import siloapplogo from "../assets/siloapp_logo2.svg";
import vector23 from "../assets/vector23.png";
import vector24 from "../assets/vector24.png";
import useMobileMenu from "../hooks/useMobileMenu";

const MobileMenu = () => {
	const { mobileMenu, close } = useMobileMenu();
	const location = useLocation();
	const isActivePath = (path) => {
		const pathL = location.pathname.replace("/", "");
		return path == pathL;
	};
	return (
		<Stack
			className={`flex top-0 fixed lg:hidden bg-white h-screen w-full z-50 -left-[100%] ${
				mobileMenu && "left-0"
			} transition-all duration-200 ease-in-out overflow-y-auto`}
		>
			<Stack className="z-10 flex py-8 pt-9 px-6 flex-row place-items-center h-[150px] border-b-2 border-b-gray-200 ">
				<Box>
					<img
						src={siloapplogo}
						alt="Homepage Logo"
					/>
				</Box>
				<Box className="flex flex-1 justify-end ">
					<IconButton
						onClick={close}
						className="rounded-full px-6 py-2 shadow-md hover:bg-gray-200 bg-white border-[1px] border-[#DFDFDF] border-solid group"
					>
						<Close className="text-xl group-hover:text-black" />
					</IconButton>
				</Box>
			</Stack>
			<Stack className="flex-1 px-6 pb-6">
				<List
					dense
					className="flex h-full flex-col text-center p-0 py-6 gap-3 justify-around"
				>
					<ListItem>
						<ListItemText className="text-center hover:underline cursor-pointer">
							<Link
								to={"/"}
								onClick={close}
								className={`${isActivePath("") && "text-[#6AC230]"}`}
							>
								<span className="text-3xl md:text-5xl md:leading-[55px] font-semibold">
									Home
								</span>
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText className="text-center hover:underline cursor-pointer">
							<Link
								onClick={close}
								to={"/how-it-works"}
								className={`${
									isActivePath("how-it-works") && "text-[#6AC230]"
								}`}
							>
								<span className="text-3xl md:text-5xl md:leading-[55px] font-semibold">
									How it works
								</span>
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText className="text-center hover:underline cursor-pointer">
							<Link
								onClick={close}
								to={"/whysiloapp"}
								className={`${isActivePath("whysiloapp") && "text-[#6AC230]"}`}
							>
								<span className="text-3xl md:text-5xl md:leading-[55px] font-semibold">
									Why SiloApp
								</span>
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText className="text-center hover:underline cursor-pointer">
							<Link
								onClick={close}
								to={"/FAQ"}
								className={`${isActivePath("FAQ") && "text-[#6AC230]"}`}
							>
								<span className="text-3xl md:text-5xl md:leading-[55px] font-semibold">
									FAQ
								</span>
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem>
						<ListItemText className="text-center hover:underline cursor-pointer">
							<Link
								onClick={close}
								to={"/contact"}
								className={`${isActivePath("contact") && "text-[#6AC230]"}`}
							>
								<span className="text-3xl md:text-5xl md:leading-[55px] font-semibold">
									Contact
								</span>
							</Link>
						</ListItemText>
					</ListItem>
				</List>
				<Stack className="flex-col gap-2 mt-2 md:flex-row">
					<a
						onClick={close}
						href="https://dev.thesiloapp.com/signup"
						className="py-3 md:w-1/2 bg-[#6AC230] hover:bg-[#5ba828] rounded-full capitalize"
					>
						<span className="h-full flex text-[16px] font-bold text-white justify-center place-items-center">
							Sign Up
						</span>
					</a>
					<a
						onClick={close}
						href="https://dev.thesiloapp.com/sign-in"
						className="py-3 md:w-1/2 bg-white hover:bg-gray-200 rounded-full capitalize shadow-md"
					>
						<span className="h-full flex text-[16px] font-bold text-black justify-center place-items-center">
							Sign In
						</span>
					</a>
				</Stack>
			</Stack>
			<Stack className="gap-2 pb-6 justify-center place-items-center">
				<Box className="flex gap-2 place-items-center">
					<img
						src={vector23}
						alt=""
						className="h-2 w-2"
					/>
					<span className="text-xl font-light">+40 729 016 150</span>
				</Box>
				<Box className="flex gap-2 place-items-center">
					<img
						src={vector24}
						alt=""
						className="h-2 w-2"
					/>
					<span className="text-xl font-light">adrian@agritech-center.com</span>
				</Box>
			</Stack>
		</Stack>
	);
};

export default MobileMenu;
