import { Box, Container, Typography } from "@mui/material";
import Hero from "../homepage/components/Hero";
import Footer, { scrollTop } from "../homepage/components/Footer";
import FreeTry from "../homepage/components/FreeTry";
import MobileMenu from "../MobileMenu";
import { Stack } from "@mui/system";
import page404 from "../../assets/error404.gif";
import vector38 from "../../assets/vector38.png";
import { Link } from "react-router-dom";

const Main404 = () => {
	return (
		<Stack
			id="start"
			className="flex-1 p-3 px-0 md:p-0 justify-center h-auto min-h-[500px] bg-white gap-4 md:gap-0"
		>
			<Container
				maxWidth="xl"
				className="flex-1 z-0 flex flex-col md:flex-row h-full p-0 py-20 pb-0 gap-4 md:gap-0 md:py-0 justify-center"
			>
				<Stack className="md:py-14 px-3 md:justify-center flex-row gap-2 place-items-center md:place-items-start md:w-[140px]">
					<span className="flex flex-row text-sm md:text-[14px] font-semibold place-items-center gap-2">
						<img
							src={vector38}
							className="w-2 h-2"
							alt=""
						></img>
						Page not found
					</span>
				</Stack>
				<Stack className="gap-4 justify-center flex-1 md:py-20 px-3 md:px-8 pb-4 border-b-2 border-b-[#DFDFDF] md:border-b-0 md:border-x-2 md:border-x-[#DFDFDF]">
					<img
						src={page404}
						alt=""
						className="object-fill"
					/>
					<Typography className="text-center text-2xl font-semibold">
						Looks like you are lost...
					</Typography>
					<Stack className="flex-row gap-2 mt-6 justify-center place-items-center">
						<Link
							to="/"
							onClick={() => {
								scrollTop();
							}}
							className="py-2 w-1/2 bg-black hover:bg-[#5ba828] rounded-full capitalize text-center"
						>
							<span className="flex justify-center place-items-center text-[14px] font-bold text-white">
								Back to home page
							</span>
						</Link>
					</Stack>
				</Stack>
				<Stack className="flex w-full px-3 py-6 md:py-14 gap-4 md:max-w-[270px] lg:min-w-[420px] lg:max-w-[420px] justify-center"></Stack>
			</Container>
		</Stack>
	);
};
const Page404 = () => {
	return (
		<>
			<MobileMenu />
			<Box className={`flex flex-col relative min-h-screen`}>
				<Hero />
				<Main404 />
				<FreeTry />
				<Footer />
			</Box>
		</>
	);
};

export default Page404;
