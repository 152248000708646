import {
	Box,
	Button,
	CircularProgress,
	Container,
	Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import vector38 from "../../../assets/vector38.png";
import vector23 from "../../../assets/vector23.png";
import vector24 from "../../../assets/vector24.png";
import Field from "./Field";
import React, { useState } from "react";

const SayingHello = ({
	send_data,
	isErrorMsg,
	errorMsg,
	setErrorMsg,
	sendingData,
}) => {
	const [msgRef, setMsgRef] = useState({});
	const [emailRef, setEmailRef] = useState({});
	return (
		<Stack
			id="start"
			className="flex-1 p-3 px-0 md:p-0 justify-center h-auto min-h-[680px] md:min-h-[500px] bg-white gap-4 md:gap-0"
		>
			<Container
				maxWidth="xl"
				className="flex-1 z-10 flex flex-col md:flex-row h-full p-0 py-20 pb-0 gap-4 md:gap-0 md:py-0 justify-center"
			>
				<Stack className="md:py-14 md:justify-center px-3 flex-row gap-2 place-items-center md:place-items-start md:w-[140px]">
					<span className="flex flex-row text-sm md:text-[14px] font-semibold place-items-center gap-2">
						<img
							src={vector38}
							className="w-2 h-2"
							alt=""
						></img>
						Saying hello
					</span>
				</Stack>
				<Stack className="justify-center gap-4 md:flex-1 md:py-20 px-3 md:px-8 pb-4 border-b-2 border-b-[#DFDFDF] md:border-b-0 md:border-x-2 md:border-x-[#DFDFDF]">
					<Typography className="w-[70%] text-lg font-semibold text-black leading-5">
						Leave us a message
					</Typography>
					<Stack className="gap-4">
						<Field
							type="email"
							name="email"
							required={true}
							setRef={setEmailRef}
							setErrorMsg={setErrorMsg}
							placeholder="Your e-mail here"
						/>
						<Field
							textArea
							type="text"
							name="message"
							required={true}
							setRef={setMsgRef}
							setErrorMsg={setErrorMsg}
							placeholder="Your message"
						/>
					</Stack>
					{isErrorMsg && (
						<span className="text-xs text-[#FE135A]">{errorMsg}</span>
					)}
					<Button
						onClick={() => {
							send_data([emailRef, msgRef]);
						}}
						disabled={sendingData}
						className={`${
							sendingData && "disabled:bg-green-800 disabled:text-gray-300"
						} md:w-44 md:place-self-end shadow-md rounded-full mt-6 text-white bg-[#6AC238] hover:bg-[#5ba828] font-semibold text-xs py-3 px-8 capitalize`}
					>
						{sendingData && (
							<CircularProgress className="absolute left-1/2 -translate-x-1/2" />
						)}
						Send message
					</Button>
				</Stack>
				<Stack className="flex w-full px-3 py-6 md:py-14 gap-4 md:max-w-[270px] lg:min-w-[420px] lg:max-w-[420px] justify-center">
					<Box className="flex gap-4 place-items-center p-4 bg-[#FFF8EB] rounded-lg overflow-hidden">
						<img
							src={vector24}
							alt=""
							className="h-4 w-4"
						/>
						<span className="text-lg font-light">
							adrian@agritech-center.com
						</span>
					</Box>
					<Box className="flex gap-4 place-items-center p-4 bg-[#FFF8EB] rounded-lg overflow-hidden">
						<img
							src={vector23}
							alt=""
							className="h-4 w-4"
						/>
						<span className="text-lg font-light">+40 729 016 150</span>
					</Box>
				</Stack>
			</Container>
		</Stack>
	);
};
export default SayingHello;
