import { Box, Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import vector4 from "../../../assets/vector4.png";
import arrow_right from "../../../assets/arrow_right.png";
import arrow_left from "../../../assets/arrow_left.png";
import howwedosec from "../../../assets/howwedo_rightsection.png";
import { useSlider } from "../../../hooks/useSlider";

const HowWeDoIt = () => {
	const { dots, next_slide, prev_slide, calc_transform } = useSlider();
	window.addEventListener("resize", calc_transform);
	return (
		<Container
			maxWidth="xl"
			className="flex flex-col md:flex-row py-20 md:py-0 pb-0 min-h-[550px] gap-4 md:gap-0 px-0 overflow-hidden"
		>
			<Stack className="md:py-14 px-3 flex-row gap-2 md:justify-center place-items-center md:place-items-start md:max-w-[140px] md:min-w-[140px]">
				<span className="flex flex-row text-sm md:text-[14px] font-semibold place-items-center gap-2">
					<img
						src={vector4}
						className="w-2 h-2"
						alt=""
					></img>
					How we do it
				</span>
			</Stack>
			<Stack
				id="layoutsW"
				className="md:flex-1 md:py-14 gap-4 px-3 md:px-8 flex-wrap md:border-x-2 md:border-x-[#DFDFDF]"
			>
				<Stack className="flex-row flex-wrap gap-4">
					<Stack
						id="dots"
						className="flex-row flex-1 gap-2 justify-start place-items-center"
					>
						{dots.map((i) => {
							return (
								<Stack
									key={i}
									className="dot relative"
								>
									<Box className="w-10 h-1 rounded-lg bg-[#DFDFDF]"></Box>
									<Box className="top-0 absolute w-0 h-1 rounded-lg bg-[#6AC230]"></Box>
								</Stack>
							);
						})}
					</Stack>
					<Stack className="flex-row gap-4 justify-center place-items-center">
						<img
							src={arrow_left}
							onClick={prev_slide}
							alt=""
							className="hover:cursor-pointer hover:bg-[#6AC230] rounded-full"
						/>
						<img
							src={arrow_right}
							onClick={next_slide}
							alt=""
							className="hover:cursor-pointer hover:bg-[#6AC230] rounded-full"
						/>
					</Stack>
				</Stack>
				<Stack
					id="layouts"
					className="w-full flex-1 flex-col overflow-hidden"
				>
					<Stack className="flex-row transition-transform duration-500">
						<Stack
							id="layout"
							className="min-w-full mt-4"
						>
							<Typography className="font-['Arimo'] text-3xl lg:text-[40px] leading-8 lg:leading-[45px] font-semibold">
								Layout
							</Typography>
							<Typography className="text-lg leading-5 my-4 font-light">
								Choose the layout of the Plant only with a few clicks. The
								design is highly customizable, allowing projects suitable for
								any needs, application, standards and regardless specific site
								restrictions.
							</Typography>
							<Typography className="text-xs text-[#737373] leading-5">
								The most common industry standards are embedded in Silo App’s
								algorithms, allowing automated design and on the spot generation
								of Layout, Flow Diagram, BoQ and Quotation.
							</Typography>
							<Stack className="flex md:grid md:grid-cols-3 flex-row flex-wrap gap-4 overflow-x-hidden mt-4">
								<Stack className="w-full p-4 min-h-[100px] rounded-2xl bg-[#FFF8EB] gap-1">
									<Typography className="text-lg font-medium">
										less than 1 minute
									</Typography>
									<span className="text-xs text-[#737373]">
										avarage design duration
									</span>
								</Stack>
								<Stack className="w-full p-4 min-h-[100px] rounded-2xl bg-[#FFF8EB] gap-1">
									<Typography className="text-lg font-medium">A.I</Typography>
									<span className="text-xs text-[#737373]">driven design</span>
								</Stack>
								<Stack className="w-full p-4 min-h-[100px] rounded-2xl bg-[#FFF8EB] gap-1">
									<Typography className="text-lg font-medium">
										No experience
									</Typography>
									<span className="text-xs text-[#737373]">
										needed for the design
									</span>
								</Stack>
							</Stack>
						</Stack>
						<Stack
							id="layout"
							className="min-w-full mt-4"
						>
							<Typography className="font-['Arimo'] text-3xl lg:text-[40px] leading-8 lg:leading-[45px] font-semibold">
								3D design of your Silo Plant
							</Typography>
							<Typography className="text-lg leading-5 my-4 font-light">
								Based on industry standards, SiloApp generates automatically the
								complete 3D design of your Silo Plant.
							</Typography>
							<Stack className="flex md:grid md:grid-cols-3 flex-row flex-wrap gap-4 overflow-x-hidden mt-4">
								<Stack className="w-full p-4 min-h-[100px] rounded-2xl bg-[#FFF8EB] gap-1">
									<Typography className="text-lg font-medium">
										less than 1 minute
									</Typography>
									<span className="text-xs text-[#737373]">
										avarage design duration
									</span>
								</Stack>
								<Stack className="w-full p-4 min-h-[100px] rounded-2xl bg-[#FFF8EB] gap-1">
									<Typography className="text-lg font-medium">A.I</Typography>
									<span className="text-xs text-[#737373]">driven design</span>
								</Stack>
								<Stack className="w-full p-4 min-h-[100px] rounded-2xl bg-[#FFF8EB] gap-1">
									<Typography className="text-lg font-medium">
										No experience
									</Typography>
									<span className="text-xs text-[#737373]">
										needed for the design
									</span>
								</Stack>
							</Stack>
						</Stack>
						<Stack
							id="layout"
							className="min-w-full mt-4"
						>
							<Typography className="font-['Arimo'] text-3xl lg:text-[40px] leading-8 lg:leading-[45px] font-semibold">
								Flow Diagram
							</Typography>
							<Typography className="text-lg leading-5 my-4 font-light">
								After customizing the 3D layout according to your needs, SiloApp
								automatically generates the Flow Diagram and the List of
								Equipment.
							</Typography>
							<Stack className="flex md:grid md:grid-cols-3 flex-row flex-wrap gap-4 overflow-x-hidden mt-4">
								<Stack className="w-full p-4 min-h-[100px] rounded-2xl bg-[#FFF8EB] gap-1">
									<Typography className="text-lg font-medium">
										less than 1 minute
									</Typography>
									<span className="text-xs text-[#737373]">
										avarage design duration
									</span>
								</Stack>
								<Stack className="w-full p-4 min-h-[100px] rounded-2xl bg-[#FFF8EB] gap-1">
									<Typography className="text-lg font-medium">A.I</Typography>
									<span className="text-xs text-[#737373]">driven design</span>
								</Stack>
								<Stack className="w-full p-4 min-h-[100px] rounded-2xl bg-[#FFF8EB] gap-1">
									<Typography className="text-lg font-medium">
										No experience
									</Typography>
									<span className="text-xs text-[#737373]">
										needed for the design
									</span>
								</Stack>
							</Stack>
						</Stack>
						<Stack
							id="layout"
							className="min-w-full mt-4"
						>
							<Typography className="font-['Arimo'] text-3xl lg:text-[40px] leading-8 lg:leading-[45px] font-semibold">
								Farmers and Dealers
							</Typography>
							<Typography className="text-lg leading-5 my-4 font-light">
								As a Farmer, you can send Inquiries to the equipement producer
								of your choice. As a Dealer or as an equipment producer, you can
								download the pre-engineering and equipment specification, or you
								can generate a quotation automatically.
							</Typography>
							<Stack className="flex md:grid md:grid-cols-3 flex-row flex-wrap gap-4 overflow-x-hidden mt-4">
								<Stack className="w-full p-4 min-h-[100px] rounded-2xl bg-[#FFF8EB] gap-1">
									<Typography className="text-lg font-medium">
										less than 1 minute
									</Typography>
									<span className="text-xs text-[#737373]">
										avarage design duration
									</span>
								</Stack>
								<Stack className="w-full p-4 min-h-[100px] rounded-2xl bg-[#FFF8EB] gap-1">
									<Typography className="text-lg font-medium">A.I</Typography>
									<span className="text-xs text-[#737373]">driven design</span>
								</Stack>
								<Stack className="w-full p-4 min-h-[100px] rounded-2xl bg-[#FFF8EB] gap-1">
									<Typography className="text-lg font-medium">
										No experience
									</Typography>
									<span className="text-xs text-[#737373]">
										needed for the design
									</span>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<Stack className="min-w-full md:min-w-[270px] md:max-w-[270px] lg:max-w-[420px] justify-center">
				<img
					src={howwedosec}
					alt=""
					className="object-fill max-h-[450px] md:max-h-max md:h-full"
				/>
			</Stack>
		</Container>
	);
};

export default HowWeDoIt;
