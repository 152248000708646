import { Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import vector29 from "../../../assets/vector29.png";
import testsec from "../../../assets/testimg_section.png";
import { Link } from "react-router-dom";
import { useSelection } from "../../../hooks/useSelected";
import { scrollTop } from "./Footer";

const FreeTry = () => {
	const { setBookDemo } = useSelection();
	return (
		<Stack className="p-3 px-0 md:p-0 justify-center h-[500px] md:min-h-[450px] md:h-auto gap-4 md:gap-0 bg-freetry-bg bg-center bg-cover bg-no-repeat">
			<Container
				maxWidth="xl"
				className="flex-1 z-10 flex flex-col md:flex-row h-full p-0 justify-center"
			>
				<Stack className="hidden md:flex md:py-12 px-3 flex-row gap-2 place-items-center md:justify-center md:place-items-end md:max-w-[140px]">
					<span className="flex flex-col text-sm md:text-[14px] font-semibold place-items-start gap-2 text-white leading-4">
						<img
							src={vector29}
							className="w-2 h-2"
							alt=""
						></img>
						Anybody can design industrial plants, due to its algorithms with
						embedded industry standards.
					</span>
				</Stack>
				<Stack className="md:flex-1 md:py-20 px-3 md:px-8 md:border-x-2 md:border-x-[#DFDFDF] justify-center">
					<Stack>
						<Typography className="font-['Arimo'] text-3xl lg:text-[40px] leading-10 lg:leading-[45px] font-semibold text-white">
							Want to give it a free run first?
						</Typography>
					</Stack>
					<Stack className="mt-4">
						<Typography className="text-lg text-[#DFDFDF] font-light leading-5">
							We are providing expert consulting based on your needs and wishes
							in order to achieve the greatest success with your projects!
						</Typography>
					</Stack>
					<Stack className="flex-row gap-2 mt-6">
						<Link
							to="/contact"
							onClick={() => {
								scrollTop();
								setBookDemo();
							}}
							className="py-3 w-[180px] bg-[#6AC230] hover:bg-[#5ba828] rounded-full capitalize text-center"
						>
							<span className="flex justify-center place-items-center text-[14px] font-bold text-white">
								Book a demo
							</span>
						</Link>
					</Stack>
				</Stack>
				<Stack className="hidden md:flex min-w-[270px] sm:max-w-[270px] md:max-w-[270px] lg:min-w-[420px] lg:max-w-[420px] justify-center">
					<img
						src={testsec}
						alt=""
						className="object-fill md:h-[60%] lg:h-[80%]"
					/>
				</Stack>
			</Container>
		</Stack>
	);
};

export default FreeTry;
