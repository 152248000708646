/* eslint-disable eqeqeq */
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

const selectionState = atom({
	key: "isAvailableState",
	default: "1",
});

export const useSelection = (setErrorMsg) => {
	const [selection, setSelectionState] = useRecoilState(selectionState);
	const setSelection = (e) => {
		setErrorMsg("");
		setSelectionState(e.target.id);
		return;
	};
	const setBookDemo = () => {
		setSelectionState("2");
		return;
	};
	const setAskConsultant = () => {
		setSelectionState("3");
		return;
	};
	const isSelected = useCallback((id) => {
		return selection == id;
	}, [selection])
	return {
		setBookDemo,
		setAskConsultant,
		setSelection,
		selection,
		isSelected,
		setSelectionState
	};
};
