import { useCallback, useEffect, useRef, useState } from "react";

export const useSlider = () => {
	const el = useRef();
	const el_wrapper = useRef();
	const curSlide = useRef(1);
	const maxSlides = useRef(1);
	const curSeconds = useRef(0);
	const max_seconds = useRef(40);
	const [dots, setDots] = useState([]);
	const generate_dots = useCallback(() => {
		let list = [];
		for (let i = 0; i < maxSlides.current; i++) {
			list.push(i);
		}
		setDots(list);
	}, [maxSlides, setDots]);
	const reset_dots = () => {
		const el = document.querySelector("#dots");
		let percent = (curSeconds.current * 100) / max_seconds.current;
		for (let i = 0; i < el.childNodes.length; i++) {
			let dot = el.children[i];
			let dot_progress = dot.children[1];
			if (curSlide.current - 1 === i) {
				dot_progress.style.width = percent + "%";
			} else {
				if (i < curSlide.current - 1) {
					dot_progress.style.width = "100%";
				} else {
					dot_progress.style.width = "0%";
				}
			}
		}
		return;
	};
	const next_slide = () => {
		curSlide.current = curSlide.current + 1;
		move_slide();
		return;
	};
	const prev_slide = () => {
		curSlide.current = curSlide.current - 1;
		move_slide();
		return;
	};
	const calc_transform = () => {
		el_wrapper.current.style.transform =
			"translateX(" + -((curSlide.current - 1) * 100) + "%)";
		return;
	};
	const move_slide = () => {
		curSeconds.current = 0;
		if (curSlide.current > maxSlides.current) {
			curSlide.current = 1;
			el_wrapper.current.style.transform = "translateX(0px)";
			return;
		}
		if (curSlide.current <= 0) {
			curSlide.current = 1;
			el_wrapper.current.style.transform = "translateX(0px)";
			return;
		}
		calc_transform();
		return;
	};
	useEffect(() => {
		let id;
		el.current = document.querySelector("#layouts");
		el_wrapper.current = el.current.children[0];
		maxSlides.current = el_wrapper.current.childNodes.length;
		generate_dots();
		id = setInterval(() => {
			if (curSeconds.current > max_seconds.current) {
				next_slide();
				return;
			}
			reset_dots();
			curSeconds.current = curSeconds.current + 1;
			return;
		}, 1000);
		return () => {
			clearInterval(id);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { dots, maxSlides, curSlide, next_slide, prev_slide, calc_transform };
};
