import { Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import vector34 from "../../../assets/vector34.png";
import vector35 from "../../../assets/vector35.png";
import vector36 from "../../../assets/vector36.png";
import vector37 from "../../../assets/vector37.png";
import vector33 from "../../../assets/vector33.png";
import whatwedo2sec from "../../../assets/whatwedo2_rightsection.png";

const Modules = () => {
	return (
		<Stack className="p-3 px-0 md:p-0 justify-center h-auto min-h-[500px] bg-[#FFF8EB] gap-4 md:gap-0">
			<Container
				maxWidth="xl"
				className="flex-1 z-10 flex flex-col md:flex-row h-full p-0 py-16 md:py-0 gap-4 md:gap-0"
			>
				<Stack className="md:py-14 px-3 flex-row gap-2 md:justify-center place-items-center md:place-items-start md:min-w-[140px]">
					<span className="flex flex-row text-sm md:text-[14px] font-semibold place-items-center gap-2 text-black">
						<img
							src={vector33}
							className="w-2 h-2"
							alt=""
						></img>
						Modules
					</span>
				</Stack>
				<Stack className="md:flex-1 md:py-20 gap-4 justify-center flex-wrap md:border-x-2 md:border-x-[#DFDFDF]">
					<Stack className="px-3 md:px-8 gap-4">
						<Typography className="text-3xl leading-8 lg:text-[40px] lg:leading-[46px] font-semibold text-black">
							Our main modules
						</Typography>
						<Typography className="text-lg text-black font-light leading-5">
							Farmers and professionals alike can benefit from the same
							knowledge anywhere in the world, as Silo App is available
							worldwide, on desktop or any mobile device.
						</Typography>
					</Stack>
					<Stack className="flex flex-col gap-4 px-3 md:px-8">
						<Stack className="w-full md:flex-row md:min-w-0 p-4 h-auto min-h-[150px] rounded-2xl bg-white gap-4">
							<Stack className="relative w-[60px] h-[40px] md:h-full md:justify-center">
								<img
									src={vector34}
									alt=""
									className="object-cover h-6 w-6 place-self-start md:place-self-center"
								/>
							</Stack>
							<Stack className="flex-1 justify-center gap-2">
								<Typography className="text-lg font-medium">
									3D design
								</Typography>
								<Typography className="text-sm text-[#737373] font-light leading-5">
									Choose only the position of the silo bins and SiloApp will
									automatically generate the complete silo plant, with all the
									necessary equipment positioned according to industry
									standards. Customize the design until it fits into
									requirements.
								</Typography>
							</Stack>
						</Stack>
						<Stack className="w-full md:flex-row md:min-w-0 p-4 h-auto min-h-[150px] rounded-2xl bg-white gap-4">
							<Stack className="relative w-[60px] h-[40px] sm:h-full md:justify-center">
								<img
									src={vector35}
									alt=""
									className="object-cover h-6 w-6 place-self-start md:place-self-center"
								/>
							</Stack>
							<Stack className="flex-1 justify-center gap-2">
								<Typography className="text-lg font-medium">
									Flow Diagram
								</Typography>
								<Typography className="text-sm text-[#737373] font-light leading-5">
									Based on the 3D, the Flow Diagram it is automatically
									generated by our innovative software. You can add or erase,
									change the type of the equipment or move it to the desired
									position in the flow.
								</Typography>
							</Stack>
						</Stack>
						<Stack className="w-full md:flex-row md:min-w-0 p-4 h-auto min-h-[150px] rounded-2xl bg-white gap-4">
							<Stack className="relative w-[60px] h-[40px] sm:h-full md:justify-center">
								<img
									src={vector36}
									alt=""
									className="object-cover h-6 w-6 place-self-start md:place-self-center"
								/>
							</Stack>
							<Stack className="flex-1 justify-center gap-2">
								<Typography className="text-lg font-medium">
									Complete Plant Quotation
								</Typography>
								<Typography className="text-sm text-[#737373] font-light leading-5">
									Based on the design documents, the list of equipment is
									generated automatically. There is only a small step left to
									the price quotation.
								</Typography>
							</Stack>
						</Stack>
						<Stack className="w-full md:flex-row md:min-w-0 p-4 h-auto min-h-[150px] rounded-2xl bg-white gap-4">
							<Stack className="relative w-[60px] h-[40px] sm:h-full md:justify-center">
								<img
									src={vector37}
									alt=""
									className="object-cover h-6 w-6 place-self-start md:place-self-center"
								/>
							</Stack>
							<Stack className="flex-1 justify-center gap-2">
								<Typography className="text-lg font-medium">
									Single Machines Inquiries
								</Typography>
								<Typography className="text-sm text-[#737373] font-light leading-5">
									The users can send Inquiries to producers and dealers of grain
									handling and storage equipment for single machines projects.
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
				<Stack className="min-w-full md:min-w-[270px] sm:max-w-[270px] md:max-w-[270px] lg:max-w-[420px] justify-center">
					<img
						src={whatwedo2sec}
						alt=""
						className="object-fill md:h-[60%] lg:h-[80%]"
					/>
				</Stack>
			</Container>
		</Stack>
	);
};

export default Modules;
