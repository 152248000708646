import { Box } from "@mui/system";
import { SnackbarProvider } from "notistack";
import Footer from "../homepage/components/Footer";
import FreeTry from "../homepage/components/FreeTry";
import DirectFAQ from "../howitworks/components/DirectFAQ";
import MobileMenu from "../MobileMenu";
import Hero from "./components/Hero";
import Modules from "./components/Modules";
import Solutions from "./components/Solutions";

const WhySiloApp = () => {
	return (
		<SnackbarProvider maxSnack={3}>
			<MobileMenu />
			<Box className={`relative min-h-screen overflow-hidden`}>
				<Hero />
				<Solutions />
				<Modules />
				<DirectFAQ />
				<FreeTry />
				<Footer />
			</Box>
		</SnackbarProvider>
	);
};

export default WhySiloApp;
