import { Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import vector19 from "../../../assets/vector19.png";
import vector20 from "../../../assets/vector20.png";
import vector21 from "../../../assets/vector21.png";
import vector22 from "../../../assets/vector22.png";
import vector40 from "../../../assets/vector40.png";
import whatwedo2sec from "../../../assets/whatwedo2_rightsection.png";

const Services = () => {
	return (
		<Stack className="p-3 px-0 md:p-0 justify-center min-h-[700px] bg-[#1B321E] gap-4 md:gap-0">
			<Container
				maxWidth="xl"
				className="flex-1 z-10 flex flex-col md:flex-row h-full p-0 py-16 md:py-0 gap-4 md:gap-0"
			>
				<Stack className="md:py-14 px-3 flex-row gap-2 md:justify-center place-items-center md:place-items-start md:min-w-[140px]">
					<span className="flex flex-row text-sm md:text-[14px] font-semibold place-items-center gap-2 text-white">
						<img
							src={vector40}
							className="w-2 h-2"
							alt=""
						></img>
						Services
					</span>
				</Stack>
				<Stack className="md:flex-1 md:py-20 gap-4 justify-center flex-wrap md:border-x-2 md:border-x-[#DFDFDF]">
					<Stack className="px-3 md:px-8 gap-3 mb-4">
						<Typography className="font-['Arimo'] text-3xl lg:text-[40px] leading-10 lg:leading-[45px] font-semibold text-white">
							What we can offer you
						</Typography>
						<Typography className="text-lg text-[#DFDFDF] leading-5">
							Farmers and professionals alike can benefit from the same
							knowledge anywhere in the world, as Silo App is available
							worldwide, on desktop or any mobile device.
						</Typography>
					</Stack>
					<Stack className="hidden md:flex flex-row md:flex-wrap gap-4 px-8">
						<div className="w-full flex flex-row gap-4">
							<Stack className="md:w-1/2 min-h-[150px] p-6 h-auto rounded-2xl bg-[rgba(255,255,255,0.3)] gap-2">
								<Stack className="relative w-6 h-6">
									<img
										src={vector19}
										alt=""
									/>
								</Stack>
								<Stack>
									<Typography className="text-lg font-medium text-white">
										White Label
									</Typography>
									<span className="text-base text-white">
										Use our software under your own brand.
									</span>
								</Stack>
							</Stack>
							<Stack className="md:w-1/2 min-h-[150px] p-6 rounded-2xl bg-[rgba(255,255,255,0.3)] gap-2">
								<Stack className="relative w-6 h-6">
									<img
										src={vector20}
										alt=""
									/>
								</Stack>
								<Stack>
									<Typography className="text-lg font-medium text-white">
										Custom Modules
									</Typography>
									<span className="text-base text-white">
										Customize your own version of SiloApp.
									</span>
								</Stack>
							</Stack>
						</div>
						<div className="w-full flex flex-row gap-4">
							<Stack className="md:w-1/2 min-h-[150px] p-6 h-auto rounded-2xl bg-[rgba(255,255,255,0.3)] gap-2">
								<Stack className="relative w-6 h-6">
									<img
										src={vector21}
										alt=""
									/>
								</Stack>
								<Stack>
									<Typography className="text-lg font-medium text-white">
										R & D
									</Typography>
									<span className="text-base text-white">
										We build digital solutions for the Grain Industry.
									</span>
								</Stack>
							</Stack>
							<Stack className="md:w-1/2 min-h-[150px] p-6 h-auto rounded-2xl bg-[rgba(255,255,255,0.3)] gap-2">
								<Stack className="relative w-6 h-6">
									<img
										src={vector22}
										alt=""
									/>
								</Stack>
								<Stack>
									<Typography className="text-lg font-medium text-white">
										Consultancy
									</Typography>
									<span className="text-base text-white">
										We support you in your projects.
									</span>
								</Stack>
							</Stack>
						</div>
					</Stack>
				</Stack>
				<Stack className="md:hidden flex flex-row md:flex-wrap gap-4 px-3 overflow-x-auto scrollbar-hide">
					<div className="flex flex-row gap-4">
						<Stack className="min-w-[330px] md:w-1/2 md:min-w-0 p-6 h-auto rounded-2xl bg-[rgba(255,255,255,0.3)] gap-2">
							<Stack className="relative w-6 h-6">
								<img
									src={vector19}
									alt=""
								/>
							</Stack>
							<Stack>
								<Typography className="text-lg font-medium text-white">
									White Label
								</Typography>
								<span className="text-base text-white">
									Use our software under your own brand.
								</span>
							</Stack>
						</Stack>
						<Stack className="min-w-[330px] md:w-1/2 md:min-w-0 p-6 h-auto rounded-2xl bg-[rgba(255,255,255,0.3)] gap-2">
							<Stack className="relative w-6 h-6">
								<img
									src={vector20}
									alt=""
								/>
							</Stack>
							<Stack>
								<Typography className="text-lg font-medium text-white">
									Custom Modules
								</Typography>
								<span className="text-base text-white">
									Customize your own version of SiloApp.
								</span>
							</Stack>
						</Stack>
					</div>
					<div className="flex flex-row gap-2">
						<Stack className="min-w-[330px] md:w-1/2 md:min-w-0 p-6 h-auto rounded-2xl bg-[rgba(255,255,255,0.3)] gap-2">
							<Stack className="relative w-6 h-6">
								<img
									src={vector21}
									alt=""
								/>
							</Stack>
							<Stack>
								<Typography className="text-lg font-medium text-white">
									R & D
								</Typography>
								<span className="text-base text-white">
									We build digital solutions for the Grain Industry.
								</span>
							</Stack>
						</Stack>
						<Stack className="min-w-[330px] md:w-1/2 md:min-w-0 p-6 h-auto rounded-2xl bg-[rgba(255,255,255,0.3)] gap-2">
							<Stack className="relative w-6 h-6">
								<img
									src={vector22}
									alt=""
								/>
							</Stack>
							<Stack>
								<Typography className="text-lg font-medium text-white">
									Consultancy
								</Typography>
								<span className="text-base text-white">
									We support you in your projects.
								</span>
							</Stack>
						</Stack>
					</div>
				</Stack>
				<Stack className="min-w-full md:min-w-[270px] sm:max-w-[270px] md:max-w-[270px] lg:min-w-[420px] justify-center">
					<img
						src={whatwedo2sec}
						alt=""
						className="w-full object-cover sm:h-[60%] md:h-[80%]"
					/>
				</Stack>
			</Container>
		</Stack>
	);
};

export default Services;
