import { Box } from "@mui/material";
import Footer from "../homepage/components/Footer";
import FreeTry from "../homepage/components/FreeTry";
import Hero from "../homepage/components/Hero";
import MobileMenu from "../MobileMenu";
import AskPricing from "./components/AskPricing";

const Pricing = () => {
	return (
		<>
			<MobileMenu />
			<Box className={`flex flex-col relative min-h-screen`}>
				<Hero />
				<AskPricing />
				<FreeTry />
				<Footer />
			</Box>
		</>
	);
};

export default Pricing;
