import { Box } from "@mui/system";
import Benefits from "./components/Benefits";
import Footer from "./components/Footer";
import ForFarmers from "./components/ForFarmers";
import FreeTry from "./components/FreeTry";
import Hero from "./components/Hero";
import HowWeDoIt from "./components/Howwedoit";
import SeeHowItWorks from "./components/SeeHowItWorks";
import Services from "./components/Services";
import StartPrototyping from "./components/StartPrototyping";
import Whatwedo from "./components/Whatwedo";
import MobileMenu from "../MobileMenu";
import { SnackbarProvider } from "notistack";

const Homepage = () => {
	return (
		<SnackbarProvider maxSnack={3}>
			<MobileMenu />
			<Box className={`relative min-h-screen overflow-hidden`}>
				<Hero />
				<Whatwedo />
				<StartPrototyping />
				<HowWeDoIt />
				<SeeHowItWorks />
				<Benefits />
				<ForFarmers />
				<Services />
				<FreeTry />
				<Footer />
			</Box>
		</SnackbarProvider>
	);
};

export default Homepage;
