import { PlayArrow } from "@mui/icons-material";
import { Button, Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import vector31 from "../../../assets/vector31.png";
// eslint-disable-next-line no-unused-vars
const SectionTest = () => {
	return (
		<Stack className="md:flex-1 md:py-20 px-3 pb-4 border-b-2 justify-center border-b-[#DFDFDF] md:border-b-0 md:border-x-2 md:border-x-[#DFDFDF]">
			<Stack className="gap-2">
				<Typography className="w-[70%] text-2xl font-semibold text-black">
					Start and organize your individual silo layout projects
				</Typography>
				<Typography className="text-base text-black font-light leading-5">
					A smart design tool, which enables creation and 3D visualization of
					Silo Plants with only a few clicks.
				</Typography>
				<Typography className="text-sm text-[#737373] font-light leading-5">
					The most common industry standards are embedded in Silo App’s
					algorithms, allowing automated design and on the spot generation of
					Layout, Flow Diagram, BoQ and Quotation.
				</Typography>
				<Typography className="text-sm text-[#737373] font-light leading-5">
					The design is highly customizable, allowing projects suitable for any
					needs, application, standards and regardless specific site
					restrictions.
				</Typography>
			</Stack>
			<Stack className="flex-row gap-2 mt-6 max-w-[350px]">
				<a
					target={"_blank"}
					rel="noreferrer"
					href="https://youtu.be/JkIS3JlwUZg"
					className="py-3 w-1/2 bg-black hover:bg-gray-300 rounded-full capitalize text-center group"
				>
					<span className="flex justify-center place-items-center gap-2 text-[14px] font-bold text-white group-hover:text-black">
						<PlayArrow className="text-xl" /> Play video
					</span>
				</a>
			</Stack>
		</Stack>
	);
};
const Section1 = () => {
	return (
		<Stack className="md:flex-1 md:py-20 px-3 md:px-8 pb-4 border-b-2 justify-center border-b-[#DFDFDF] md:border-b-0 md:border-x-2 md:border-x-[#DFDFDF]">
			<Stack className="gap-4">
				<Typography className="text-3xl leading-8 lg:text-[40px] lg:leading-[46px] font-semibold text-black">
					Select Layout
				</Typography>
				<Typography className="text-lg text-[#737373] font-light leading-5">
					Choose the layout of the Plant only with a few clicks and watch your
					design come to life in real time.
				</Typography>
			</Stack>
			<Stack className="flex-row gap-2 mt-6 max-w-[350px]">
				<a
					target={"_blank"}
					rel="noreferrer"
					href="https://youtu.be/JkIS3JlwUZg"
					className="py-3 w-1/2 bg-black hover:bg-gray-300 rounded-full capitalize text-center group"
				>
					<span className="flex justify-center place-items-center gap-2 text-[14px] font-bold text-white group-hover:text-black">
						<PlayArrow className="text-xl" /> Play video
					</span>
				</a>
			</Stack>
		</Stack>
	);
};

const Section2 = () => {
	return (
		<Stack className="md:flex-1 md:py-20 px-3 md:px-8 pb-4 border-b-2 justify-center border-b-[#DFDFDF] md:border-b-0 md:border-x-2 md:border-x-[#DFDFDF]">
			<Stack className="gap-4">
				<Typography className="text-3xl leading-8 lg:text-[40px] lg:leading-[46px] font-semibold text-black">
					Intake Position
				</Typography>
				<Typography className="text-lg text-[#737373] font-light leading-5">
					Select the desired area of the silo plant’s intake and see how all the
					equipment are automatically generated.
				</Typography>
			</Stack>
			<Stack className="flex-row gap-2 mt-6 max-w-[350px]">
				<a
					target={"_blank"}
					rel="noreferrer"
					href="https://youtu.be/JkIS3JlwUZg"
					className="py-3 w-1/2 bg-black hover:bg-gray-300 rounded-full capitalize text-center group"
				>
					<span className="flex justify-center place-items-center gap-2 text-[14px] font-bold text-white group-hover:text-black">
						<PlayArrow className="text-xl" /> Play video
					</span>
				</a>
			</Stack>
		</Stack>
	);
};

const Section3 = () => {
	return (
		<Stack className="md:flex-1 md:py-20 px-3 md:px-8 pb-4 border-b-2 justify-center border-b-[#DFDFDF] md:border-b-0 md:border-x-2 md:border-x-[#DFDFDF]">
			<Stack className="gap-4">
				<Typography className="text-3xl leading-8 lg:text-[40px] lg:leading-[46px] font-semibold text-black">
					Start and organize your individual silo layout projects
				</Typography>
				<Typography className="text-lg text-[#737373] font-light leading-5">
					Customize your plant using lots of tools in order to obtain the
					perfect design suited for your needs.
				</Typography>
			</Stack>
			<Stack className="flex-row gap-2 mt-6 max-w-[350px]">
				<a
					target={"_blank"}
					rel="noreferrer"
					href="https://youtu.be/JkIS3JlwUZg"
					className="py-3 w-1/2 bg-black hover:bg-gray-300 rounded-full capitalize text-center group"
				>
					<span className="flex justify-center place-items-center gap-2 text-[14px] font-bold text-white group-hover:text-black">
						<PlayArrow className="text-xl" /> Play video
					</span>
				</a>
			</Stack>
		</Stack>
	);
};

const Section4 = () => {
	return (
		<Stack className="md:flex-1 md:py-20 px-3 md:px-8 pb-4 border-b-2 justify-center border-b-[#DFDFDF] md:border-b-0 md:border-x-2 md:border-x-[#DFDFDF]">
			<Stack className="gap-4">
				<Typography className="text-3xl leading-8 lg:text-[40px] lg:leading-[46px]  aqxl font-semibold text-black">
					Flow Diagram
				</Typography>
				<Typography className="text-lg text-[#737373] font-light leading-5">
					Automatically generate the flow diagram in seconds and download it.
				</Typography>
			</Stack>
			<Stack className="flex-row gap-2 mt-6 max-w-[350px]">
				<a
					target={"_blank"}
					rel="noreferrer"
					href="https://youtu.be/JkIS3JlwUZg"
					className="py-3 w-1/2 bg-black hover:bg-gray-300 rounded-full capitalize text-center group"
				>
					<span className="flex justify-center place-items-center gap-2 text-[14px] font-bold text-white group-hover:text-black">
						<PlayArrow className="text-xl" /> Play video
					</span>
				</a>
			</Stack>
		</Stack>
	);
};

const Section5 = () => {
	return (
		<Stack className="md:flex-1 md:py-20 px-3 md:px-8 pb-4 border-b-2 justify-center border-b-[#DFDFDF] md:border-b-0 md:border-x-2 md:border-x-[#DFDFDF]">
			<Stack className="gap-4">
				<Typography className="text-3xl leading-8 lg:text-[40px] lg:leading-[46px]  aqxl font-semibold text-black">
					Ask for price quotations
				</Typography>
				<Typography className="text-lg text-[#737373] font-light leading-5">
					When you are happy with the design, send quotation requests to your
					favorite equipment producers in the platform
				</Typography>
			</Stack>
			<Stack className="flex-row gap-2 mt-6 max-w-[350px]">
				<a
					target={"_blank"}
					rel="noreferrer"
					href="https://youtu.be/JkIS3JlwUZg"
					className="py-3 w-1/2 bg-black hover:bg-gray-300 rounded-full capitalize text-center group"
				>
					<span className="flex justify-center place-items-center gap-2 text-[14px] font-bold text-white group-hover:text-black">
						<PlayArrow className="text-xl" /> Play video
					</span>
				</a>
			</Stack>
		</Stack>
	);
};

const StartAndOrganize = () => {
	const [selectedSection, setSelectedSection] = useState("1");
	const isSelectedSection = (sec) => selectedSection.match(sec);
	return (
		<Stack
			id="start"
			className="p-3 px-0 md:p-0 justify-center min-h-[550px] bg-white gap-4 md:gap-0"
		>
			<Container
				maxWidth="xl"
				className="flex-1 z-10 flex flex-col md:flex-row h-full p-0 py-20 pb-0 gap-4 md:gap-0 md:py-0 justify-center"
			>
				<Stack className="flex md:py-14 px-3 flex-row gap-2 md:justify-center place-items-center md:place-items-start md:min-w-[140px]">
					<span className="flex justify-center place-items-center h-12 w-12 rounded-full bg-[#6AC230] text-white font-medium">
						1
					</span>
				</Stack>
				{(() => {
					switch (selectedSection) {
						case "1":
							return <Section1 />;
						case "2":
							return <Section2 />;
						case "3":
							return <Section3 />;
						case "4":
							return <Section4 />;
						case "5":
							return <Section5 />;
						default:
							return <></>;
					}
				})()}
				<Stack className="flex w-full px-3 py-4 md:py-20 gap-6 md:max-w-[270px] lg:min-w-[420px] lg:max-w-[420px] justify-center md:justify-start">
					<Button
						onClick={() => setSelectedSection("1")}
						className={
							isSelectedSection(1)
								? `text-lg capitalize font-medium text-white bg-[#6AC230] hover:bg-green-600 p-4 rounded-lg shadow-green`
								: "gap-4 text-lg capitalize font-medium text-black border-dashed border-2 border-[#1B321E] p-3 rounded-lg justify-start"
						}
					>
						<img
							src={vector31}
							alt=""
							className={isSelectedSection(1) && "hidden"}
						/>
						Select Layout
					</Button>
					<Button
						onClick={() => setSelectedSection("2")}
						className={
							isSelectedSection(2)
								? `text-lg capitalize font-medium text-white bg-[#6AC230] hover:bg-green-600 p-4 rounded-lg shadow-green`
								: "gap-4 text-lg capitalize font-medium text-black border-dashed border-2 border-[#1B321E] p-3 rounded-lg justify-start"
						}
					>
						<img
							src={vector31}
							alt=""
							className={isSelectedSection(2) && "hidden"}
						/>
						Intake Position
					</Button>
					<Button
						onClick={() => setSelectedSection("3")}
						className={
							isSelectedSection(3)
								? `text-lg font-medium capitalize text-white bg-[#6AC230] hover:bg-green-600 p-4 rounded-lg shadow-green`
								: "gap-4 text-lg font-medium capitalize text-black border-dashed border-2 border-[#1B321E] p-3 rounded-lg justify-start"
						}
					>
						<img
							src={vector31}
							alt=""
							className={isSelectedSection(3) && "hidden"}
						/>
						Customize Plant
					</Button>
					<Button
						onClick={() => setSelectedSection("4")}
						className={
							isSelectedSection(4)
								? `text-lg capitalize font-medium text-white bg-[#6AC230] hover:bg-green-600 p-4 rounded-lg shadow-green`
								: "gap-4 text-lg font-medium capitalize text-black border-dashed border-2 border-[#1B321E] p-3 rounded-lg justify-start"
						}
					>
						<img
							src={vector31}
							alt=""
							className={isSelectedSection(4) && "hidden"}
						/>
						Flow Diagram
					</Button>
					<Button
						onClick={() => setSelectedSection("5")}
						className={
							isSelectedSection(5)
								? `text-lg font-medium capitalize text-white bg-[#6AC230] hover:bg-green-600 p-4 rounded-lg shadow-green`
								: "gap-4 text-lg font-medium capitalize text-black border-dashed border-2 border-[#1B321E] p-3 rounded-lg justify-start"
						}
					>
						<img
							src={vector31}
							alt=""
							className={isSelectedSection(5) && "hidden"}
						/>
						Send Quotatition
					</Button>
				</Stack>
			</Container>
		</Stack>
	);
};

export default StartAndOrganize;
