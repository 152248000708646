import { SnackbarProvider } from "notistack";
import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import Contact from "./contact/Contact";
import FAQ from "./faq/FAQ";
import Homepage from "./homepage/Homepage";
import HowItWorks from "./howitworks/HowItWorks";
import Page404 from "./page404/Page404";
import Pricing from "./pricing/Pricing";
import WhySiloApp from "./whysiloapp/WhySiloApp";

const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<RecoilRoot>
				<Homepage />
			</RecoilRoot>
		),
		errorElement: (
			<RecoilRoot>
				<Page404 />
			</RecoilRoot>
		),
	},
	{
		path: "/how-it-works",
		element: (
			<RecoilRoot>
				<HowItWorks />
			</RecoilRoot>
		),
	},
	{
		path: "/FAQ",
		element: (
			<RecoilRoot>
				<FAQ />
			</RecoilRoot>
		),
	},
	{
		path: "/pricing",
		element: (
			<SnackbarProvider maxSnack={3}>
				<RecoilRoot>
					<Pricing />
				</RecoilRoot>
			</SnackbarProvider>
		),
	},
	{
		path: "/whysiloapp",
		element: (
			<RecoilRoot>
				<WhySiloApp />
			</RecoilRoot>
		),
	},
	{
		path: "/contact",
		element: (
			<SnackbarProvider maxSnack={3}>
				<RecoilRoot>
					<Contact />
				</RecoilRoot>
			</SnackbarProvider>
		),
	},
]);

export default router;
