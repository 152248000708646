import { ExpandMore } from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@mui/material";

const FAQItem = ({ question, answer }) => {
	return (
		<Accordion className="w-full border-0 shadow-none py-2 border-b-2 rounded-none bg-transparent">
			<AccordionSummary
				expandIcon={<ExpandMore className="w-4 h-4 text-[#6AC230]" />}
				aria-controls="panel1a-content"
                className="gap-4"
				id="panel1a-header"
			>
				<Typography className="text-lg font-semibold leading-5">
					{question}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography className="text-base font-normal">
					{answer}
				</Typography>
			</AccordionDetails>
		</Accordion>
	);
};

export default FAQItem;
