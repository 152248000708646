import { PlayArrow } from "@mui/icons-material";
import { Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import whatwedo2sec from "../../../assets/whatwedo2_rightsection.png";

const AddAndRequestSingle = () => {
	return (
		<Stack className="p-3 px-0 pb-0 md:p-0 justify-center min-h-[550px] bg-white gap-4 md:gap-0">
			<Container
				maxWidth="xl"
				className="flex-1 z-10 flex flex-col md:flex-row h-full p-0 py-20 pb-0 gap-4 md:gap-0 md:py-0 justify-center"
			>
				<Stack className="flex md:py-14 px-3 md:justify-center flex-row gap-2 place-items-center md:place-items-start md:min-w-[140px]">
					<span className="flex justify-center place-items-center h-12 w-12 rounded-full bg-[#6AC230] text-white font-medium">
						3
					</span>
				</Stack>
				<Stack className="justify-center md:flex-1 md:py-20 pb-4 px-3 md:px-8 border-b-2 border-b-[#DFDFDF] md:border-b-0 md:border-x-2 md:border-x-[#DFDFDF]">
					<Stack className="gap-8 md:gap-4">
						<Typography className="text-3xl leading-8 lg:text-[40px] lg:leading-[46px] font-semibold text-black">
							Create and send single machines Enquiries
						</Typography>
						<Typography className="text-lg text-black font-light leading-5">
							Optimized design & recommendations
						</Typography>
						<Typography className="text-sm text-[#737373] font-light leading-5">
							AI driven silo design optimisation that seeks the best machineries
							and parts arrangement based on user constraints and industry
							standards.
						</Typography>
					</Stack>
					<Stack className="flex-row gap-2 mt-6 max-w-[350px]">
						<a
							target={"_blank"}
							rel="noreferrer"
							href="https://youtu.be/JkIS3JlwUZg"
							className="py-3 w-1/2 bg-black hover:bg-gray-300 rounded-full capitalize text-center group"
						>
							<span className="flex gap-2 justify-center place-items-center text-[14px] font-bold text-white group-hover:text-black">
								<PlayArrow className="text-lg" /> Play video
							</span>
						</a>
					</Stack>
				</Stack>
				<Stack className="min-w-full md:min-w-[270px] sm:max-w-[270px] md:max-w-[270px] lg:max-w-[420px] justify-center pt-10">
					<img
						src={whatwedo2sec}
						alt=""
						className="object-fill sm:h-[65%] md:h-[80%]"
					/>
				</Stack>
			</Container>
		</Stack>
	);
};

export default AddAndRequestSingle;
