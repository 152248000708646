import { Box } from "@mui/material";
import moment from "moment/moment";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useSelection } from "../../hooks/useSelected";
import Footer from "../homepage/components/Footer";
import FreeTry from "../homepage/components/FreeTry";
import MobileMenu from "../MobileMenu";
import AskUs from "./components/AskUs";
import BookDemo from "./components/BookDemo";
import Hero from "./components/Hero";
import SayingHello from "./components/SayingHello";

const Contact = () => {
	const { selection } = useSelection();
	const [errorMsg, setErrorMsg] = useState("");
	const isErrorMsg = errorMsg.length > 0;
	const { enqueueSnackbar } = useSnackbar();
	const [sendingData, setSendingData] = useState(false);
	const get_type = () => {
		switch (selection) {
			case "1":
				return "Saying Hello";
			case "2":
				return "Demo Request";
			case "3":
				return "Asking for consultant";
			default:
				return "";
		}
	};
	const send_data = async (props, dateRef, phoneRef, setPhoneRef, type) => {
		let error = false;
		let message = {};
		if (type) {
			message["type"] = type;
		} else {
			message["type"] = get_type();
		}
		if (dateRef) {
			message["date"] = moment(dateRef).format("DD/MM/YYYY");
		}
		message["phone"] = phoneRef;
		props.forEach((el) => {
			if (!el.active || el.error) {
				error = true;
				el.validate_changes();
				setErrorMsg("Invalid requested fields");
				return;
			}
			message[el.name] = el.input.value;
		});
		if (phoneRef && !isValidPhoneNumber(phoneRef)) {
			error = true;
			setErrorMsg("Invalid phone number");
		}
		if (error) return;
		try {
			setSendingData(true);
			let data = await fetch(process.env.REACT_APP_CONTACT_ENDPOINT, {
				mode: "cors",
				method: "POST",
				headers: {
					referrerPolicy: "no-referrer",
					"Content-Type": "application/json",
					Authorization: "Bearer " + process.env.REACT_APP_API_KEY,
				},
				body: JSON.stringify(message),
			});
			data = await data.json();
			if (data.error) {
				setSendingData(false);
				setErrorMsg(data.error);
				setPhoneRef && setPhoneRef("");
				return;
			}
			props.forEach((el) => {
				el.setValue("");
				el.setActive(false);
				return;
			});
			setErrorMsg("");
			setSendingData(false);
			setPhoneRef && setPhoneRef("");
			enqueueSnackbar("Request have been send successfully", {
				variant: "success",
			});
			return;
		} catch (err) {
			props.forEach((el) => {
				el.setValue("");
				el.setActive(false);
			});
			setSendingData(false);
			setPhoneRef && setPhoneRef("");
			enqueueSnackbar("Error: " + err.message, {
				variant: "error",
			});
			return;
		}
	};
	return (
		<>
			<MobileMenu />
			<Box className={`flex flex-col relative min-h-screen overflow-hidden`}>
				<Hero setErrorMsg={setErrorMsg} />
				{(() => {
					switch (selection) {
						case "1":
							return (
								<SayingHello
									errorMsg={errorMsg}
									send_data={send_data}
									isErrorMsg={isErrorMsg}
									setErrorMsg={setErrorMsg}
									sendingData={sendingData}
								/>
							);
						case "2":
							return (
								<BookDemo
									errorMsg={errorMsg}
									send_data={send_data}
									isErrorMsg={isErrorMsg}
									setErrorMsg={setErrorMsg}
									sendingData={sendingData}
								/>
							);
						case "3":
							return (
								<AskUs
									errorMsg={errorMsg}
									send_data={send_data}
									isErrorMsg={isErrorMsg}
									setErrorMsg={setErrorMsg}
									sendingData={sendingData}
								/>
							);
						default:
							return <></>;
					}
				})()}
				<FreeTry />
				<Footer />
			</Box>
		</>
	);
};

export default Contact;
