import { Container, List, ListItem, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import vector32 from "../../../assets/vector32.png";
import faqrightsec from "../../../assets/faq_rightsection.png";
import longcycle from "../../../assets/longcycle.png";
import expensive from "../../../assets/expensive.png";
import scarcity from "../../../assets/scarcity.png";
import design from "../../../assets/design.png";
import access from "../../../assets/access.png";
import responsibility from "../../../assets/responsability.png";

const Solutions = () => {
	return (
		<Container
			id="start"
			maxWidth="xl"
			className="flex flex-col md:flex-row py-20 md:py-0 min-h-[500px] gap-4 md:gap-0 px-0"
		>
			<Stack className="md:py-14 px-3 md:justify-center flex-row gap-2 place-items-center md:place-items-start md:min-w-[140px]">
				<span className="flex flex-row text-sm md:text-[14px] font-semibold place-items-center gap-2">
					<img
						src={vector32}
						className="w-2 h-2"
						alt=""
					></img>
					Solutions
				</span>
			</Stack>
			<Stack className="md:flex-1 md:py-20 gap-4 md:gap-8 justify-center flex-wrap md:border-x-2 md:border-x-[#DFDFDF]">
				<Stack className="px-3 md:px-8 gap-4">
					<Typography className="text-3xl leading-8 lg:text-[40px] lg:leading-[46px] font-semibold text-black">
						What problems we solve?
					</Typography>
				</Stack>
				<List className="flex flex-col p-0 gap-3">
					<ListItem className="md:p-0 px-3 py-0 border-b-2 border-b-[#DFDFDF]">
						<Stack className="flex-row h-full">
							<Stack className="min-w-[130px] max-w-[130px]">
								<img
									src={longcycle}
									className="object-cover h-full w-full"
									alt=""
								></img>
							</Stack>
							<Stack className="flex-1 p-4 gap-4 justify-center">
								<Typography className="text-lg text-black font-semibold leading-5">
									Long Sales Cycle
								</Typography>
								<Typography className="text-base text-[#737373] font-light leading-5">
									Design & Quotation process is long and complicated, with
									several specialists involved and many trips to the customer.
								</Typography>
							</Stack>
						</Stack>
					</ListItem>
					<ListItem className="md:p-0 px-3 py-0 border-b-2 border-b-[#DFDFDF]">
						<Stack className="flex-row h-full">
							<Stack className="min-w-[130px] max-w-[130px]">
								<img
									src={expensive}
									className="object-cover h-full w-full"
									alt=""
								></img>
							</Stack>
							<Stack className="flex-1 p-4 gap-4 justify-center">
								<Typography className="text-lg text-black font-semibold leading-5">
									Expensive quotations
								</Typography>
								<Typography className="text-base text-[#737373] font-light leading-5">
									The cost to prepare a quotation lays within thousands of
									dollars to tens of thousands of dollars.
								</Typography>
							</Stack>
						</Stack>
					</ListItem>
					<ListItem className="md:p-0 px-3 py-0 border-b-2 border-b-[#DFDFDF]">
						<Stack className="flex-row h-full">
							<Stack className="min-w-[130px] max-w-[130px]">
								<img
									src={scarcity}
									className="object-cover h-full w-full"
									alt=""
								></img>
							</Stack>
							<Stack className="flex-1 p-4 gap-4 justify-center">
								<Typography className="text-lg text-black font-semibold leading-5">
									Scarcity of engineers
								</Typography>
								<Typography className="text-base text-[#737373] font-light leading-5">
									Very few specialized work-force available for scaling up your
									organization.
								</Typography>
							</Stack>
						</Stack>
					</ListItem>
					<ListItem className="md:p-0 px-3 py-0 border-b-2 border-b-[#DFDFDF]">
						<Stack className="flex-row h-full">
							<Stack className="min-w-[130px] max-w-[130px]">
								<img
									src={design}
									className="object-cover h-full w-full"
									alt=""
								></img>
							</Stack>
							<Stack className="flex-1 p-4 gap-4 justify-center">
								<Typography className="text-lg text-black font-semibold leading-5">
									Design manually
								</Typography>
								<Typography className="text-base text-[#737373] font-light leading-5">
									Smart automation solutions seldom utilized.
								</Typography>
							</Stack>
						</Stack>
					</ListItem>
					<ListItem className="md:p-0 px-3 py-0 border-b-2 border-b-[#DFDFDF]">
						<Stack className="flex-row h-full">
							<Stack className="min-w-[130px] max-w-[130px]">
								<img
									src={access}
									className="object-cover h-full w-full"
									alt=""
								></img>
							</Stack>
							<Stack className="flex-1 p-4 gap-4 justify-center">
								<Typography className="text-lg text-black font-semibold leading-5">
									Access
								</Typography>
								<Typography className="text-base text-[#737373] font-light leading-5">
									Democratization of industry standards and access to know-how
									was impossible.
								</Typography>
							</Stack>
						</Stack>
					</ListItem>
					<ListItem className="md:p-0 px-3 py-0 border-b-2 border-b-[#DFDFDF]">
						<Stack className="flex-row h-full">
							<Stack className="min-w-[130px] max-w-[130px]">
								<img
									src={responsibility}
									className="object-cover h-full w-full"
									alt=""
								></img>
							</Stack>
							<Stack className="flex-1 p-4 gap-4 justify-center">
								<Typography className="text-lg text-black font-semibold leading-5">
									Responsibility
								</Typography>
								<Typography className="text-base text-[#737373] font-light leading-5">
									Grain industry must play its part towards a Net-Zero future.
								</Typography>
							</Stack>
						</Stack>
					</ListItem>
				</List>
			</Stack>
			<Stack className="hidden md:flex min-w-full lg:min-w-[420px] md:min-w-[270px] sm:max-w-[270px] md:max-w-[270px] lg:max-w-[420px] justify-center">
				<img
					src={faqrightsec}
					alt=""
					className="object-fill h-full"
				/>
			</Stack>
		</Container>
	);
};

export default Solutions;
