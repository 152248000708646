import { Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import vector1 from "../../../assets/vector1.png";
import vector7 from "../../../assets/vector7.png";
import vector8 from "../../../assets/vector8.png";
import vector9 from "../../../assets/vector9.png";
import vector10 from "../../../assets/vector10.png";
import vector11 from "../../../assets/vector11.png";
import vector12 from "../../../assets/vector12.png";
import benefitssec from "../../../assets/benefits_rightsection2.png";

const Benefits = () => {
	return (
		<Container
			maxWidth="xl"
			className="flex flex-col md:flex-row py-20 md:py-0 pb-0 min-h-[650px] gap-4 md:gap-0 px-0"
		>
			<Stack className="md:py-14 px-3 flex-row gap-2 place-items-center md:justify-center md:place-items-start md:min-w-[140px]">
				<span className="flex flex-row text-sm md:text-[14px] font-semibold place-items-center gap-2">
					<img
						src={vector7}
						className="w-2 h-2"
						alt=""
					></img>
					Benefits
				</span>
			</Stack>
			<Stack className="flex-1 md:py-20 gap-4 flex-wrap md:border-x-2 md:border-x-[#DFDFDF]">
				<Stack className="flex-row px-3 md:px-8 gap-4 place-items-center">
					<Typography className="bg-[#F4F4F4] rounded-full w-12 h-12 flex justify-center place-items-center">
						🛠️
					</Typography>
					<Typography className="font-['Arimo'] text-3xl lg:text-[40px] leading-10 lg:leading-[45px] font-semibold">
						For Equipment Producers
					</Typography>
				</Stack>
				<Stack className="grid grid-cols-2 lg:grid-cols-3 grid-row-1 flex-wrap gap-4 px-3 md:px-8 mt-6 overflow-x-auto scrollbar-hide">
					<Stack className="p-6 rounded-[20px] min-h-[150px] bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector8}
								alt=""
							/>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">Low Cost</Typography>
							<span className="text-xs text-[#737373]">
								SiloApp reduces the cost per quotation with 90%
							</span>
						</Stack>
					</Stack>
					<Stack className="p-6 rounded-[20px] min-h-[150px] bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector9}
								alt=""
							/>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">Time</Typography>
							<span className="text-xs text-[#737373]">
								SiloApp reduces the Sales Cycle from months to less than 1 hour.
							</span>
						</Stack>
					</Stack>
					<Stack className="p-6 h-auto rounded-[20px] min-h-[150px] bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector1}
								alt=""
							/>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">
								Innovation
							</Typography>
							<span className="text-xs text-[#737373]">
								3D Layout, Flow Diagram, BoQ, Quotation and Marketplace.
							</span>
						</Stack>
					</Stack>
					<Stack className="p-6 h-auto rounded-[20px] min-h-[150px] bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector10}
								alt=""
							/>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">
								Customization
							</Typography>
							<span className="text-xs text-[#737373]">
								Fully customizable projects, to fulfill any customer need.
							</span>
						</Stack>
					</Stack>
					<Stack className="p-6 h-auto rounded-[20px] min-h-[150px] bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector11}
								alt=""
							/>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">
								Responsability
							</Typography>
							<span className="text-xs text-[#737373]">
								80% lower Carbon Foot print for the Sales Team.
							</span>
						</Stack>
					</Stack>
					<Stack className="p-6 h-auto rounded-[20px] min-h-[150px] bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector12}
								alt=""
							/>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">Global</Typography>
							<span className="text-xs text-[#737373]">
								One click away from Farmers all over the world.
							</span>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<Stack className="min-w-full md:min-w-[270px] sm:max-w-[270px] md:max-w-[270px] lg:max-w-[420px] justify-center">
				<img
					src={benefitssec}
					alt=""
					className="object-cover h-[400px] md:h-full"
				/>
			</Stack>
		</Container>
	);
};

export default Benefits;
