import siloapplogo from "../../../assets/siloapp_logo.svg";
import arrowright from "../../../assets/arrowright.png";
import { ArrowDropDown, Menu } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import useMobileMenu from "../../../hooks/useMobileMenu";

const HeroMobile = () => {
	const { open } = useMobileMenu();
	return (
		<Stack className="flex-1 grid grid-rows-layout h-full md:hidden">
			<Stack className="z-10 flex py-8 pt-9 px-6 flex-row place-items-center h-[150px] border-b-2 border-b-[rgba(255,255,255,0.3)]">
				<Box>
					<img
						src={siloapplogo}
						alt="Homepage Logo"
					/>
				</Box>
				<Box className="flex flex-1 justify-end h-14">
					<IconButton
						onClick={open}
						className="rounded-full w-24 hover:bg-gray-100 bg-white group"
					>
						<Menu className="text-2xl group-hover:text-black" />
					</IconButton>
				</Box>
			</Stack>
			<Stack className="w-full z-10 justify-center place-items-start pb-10 pt-4 px-4 gap-3">
				<div className="flex py-1 rounded-full justify-center px-4 bg-[rgba(255,255,255,0.3)] shadow-sm">
					<span className="text-white text-[14px]">
						✍️ The Marketplace of grain handling and storage equipment.
					</span>
				</div>
				<Typography className="text-5xl md:leading-10 lg:text-[75px] lg:leading-[75px] font-['Arimo'] text-white font-medium mb-2">
					Design Grain Storage plants and generate quotations in minutes at a
					fraction of cost.
				</Typography>
				<Typography className="text-[19px] text-gray-200 font-light leading-5">
					A smart design tool, which enables creation and 3D visualization of
					Silo Plants with only a few clicks.
				</Typography>
			</Stack>
			<Stack className="z-10 flex py-4 justify-center place-items-center bg-white">
				<Link
					to="/how-it-works"
					className="flex place-items-center gap-2 group"
				>
					<span className="text-sm font-bold group-hover:underline">
						Learn how it works
					</span>
					<img
						src={arrowright}
						alt=""
						className="w-3 h-3"
					></img>
				</Link>
			</Stack>
		</Stack>
	);
};

const Hero = () => {
	const { open } = useMobileMenu();
	return (
		<Box className="flex flex-col h-auto min-h-screen md:min-h-[680px] relative overflow-hidden md:overflow-visible shadow-md bg-homepage-bg-mobile bg-center lg:bg-hero-bg lg:bg-right-top lg:bg-cover bg-no-repeat">
			<div className="bg-[rgba(0,0,0,0.4)] absolute top-0 z-0 h-full w-full"></div>
			<HeroMobile />
			<Container
				maxWidth="xl"
				className="relative z-10 flex-1 flex-row h-full hidden md:flex p-0"
			>
				<IconButton className="z-40 left-[140px] -translate-x-1/2 bottom-[-50px] w-20 h-20 rounded-full shadow-md absolute bg-white hover:bg-[#5ba828] hover:shadow-md group border-2 border-[#DFDFDF]">
					<a href="#start">
						<ArrowDropDown className="text-black group-hover:text-white" />
					</a>
				</IconButton>
				<Stack className="min-w-[140px] place-items-center">
					<Box className="p-2 flex justify-center h-[130px]">
						<img
							src={siloapplogo}
							alt="Homepage Logo"
							className="object-contain"
						/>
					</Box>
				</Stack>
				<Stack className="flex-1 justify-center place-items-center lg:justify-start px-4 gap-3 border-x-2 border-x-[rgba(255,255,255,0.3)]">
					<div className="w-full flex flex-col p-0 h-full">
						<Stack className="hidden lg:flex h-[130px] flex-row flex-wrap place-items-center p-2 px-0 justify-start gap-8">
							<div>
								<div className="text-center hover:underline cursor-pointer text-[#6AC230]">
									<Link to={"/"}>
										<span className="text-base font-bold">Home</span>
									</Link>
								</div>
							</div>
							<div>
								<div className="text-center hover:underline cursor-pointer text-white">
									<Link to={"/how-it-works"}>
										<span className="text-base font-bold">How it works</span>
									</Link>
								</div>
							</div>
							<div>
								<div className="text-center hover:underline cursor-pointer text-white">
									<Link to={"/whysiloapp"}>
										<span className="text-base font-bold">Why SiloApp</span>
									</Link>
								</div>
							</div>
							<div>
								<div className="text-center hover:underline cursor-pointer text-white">
									<Link to={"/FAQ"}>
										<span className="text-base font-bold">FAQ</span>
									</Link>
								</div>
							</div>
							<div>
								<div className="text-center hover:underline cursor-pointer text-white">
									<Link to={"/contact"}>
										<span className="text-base font-bold">Contact</span>
									</Link>
								</div>
							</div>
						</Stack>
						<Stack className="flex-1 gap-4 justify-center lg:justify-start lg:my-4 md:my-[130px]">
							<div className="flex py-1 rounded-full justify-center px-4 bg-[rgba(255,255,255,0.3)] shadow-sm md:w-[430px]">
								<span className="text-white text-[14px] leading-4 p-1 text-center">
									✍️ The Marketplace of grain handling and storage equipment.
								</span>
							</div>
							<Typography className="md:text-5xl md:leading-10 lg:text-[75px] lg:leading-[75px] font-['Arimo'] text-white font-medium mb-2">
								Design Grain Storage plants and generate quotations in minutes
								at a fraction of cost.
							</Typography>
							<Typography className="w-[60%] text-xs md:text-[19px] text-gray-200 font-light leading-5">
								A smart design tool, which enables creation and 3D visualization
								of Silo Plants with only a few clicks.
							</Typography>
						</Stack>
					</div>
				</Stack>
				<Stack className="w-full md:min-w-[270px] md:max-w-[270px] lg:max-w-[420px] justify-center">
					<Box className="flex justify-end place-items-center py-6 pr-6 h-[135px] lg:hidden">
						<IconButton
							onClick={open}
							className="rounded-full h-10 px-10 hover:bg-gray-100 bg-white group"
						>
							<Menu className="text-base group-hover:text-black" />
						</IconButton>
					</Box>
					<Stack className="justify-center place-items-center flex-row gap-4 px-12 py-6 hidden lg:flex h-[135px]">
						<a
							href="https://dev.thesiloapp.com/signup"
							className="flex place-items-center w-[137px] capitalize h-10 bg-[#6AC230] hover:bg-[#5ba828] rounded-full text-center"
						>
							<span className="w-full flex justify-center place-items-center text-[16px] font-bold text-white">
								Sign Up
							</span>
						</a>
						<a
							href="https://dev.thesiloapp.com/sign-in"
							className="flex place-items-center w-auto min-w-[120px] h-10 px-6 bg-white rounded-full hover:bg-gray-200 capitalize"
						>
							<span className="w-full flex justify-center place-items-center text-[16px] font-bold text-black">
								Login
							</span>
						</a>
					</Stack>
					<Stack className="flex-1"></Stack>
					<Stack className="w-full flex py-4 justify-center place-items-center bg-white">
						<Link
							to="/how-it-works"
							className="flex place-items-center gap-2 group"
						>
							<span className="text-sm font-bold group-hover:underline">
								Learn how it works
							</span>
							<img
								src={arrowright}
								alt=""
								className="w-3 h-3"
							></img>
						</Link>
					</Stack>
				</Stack>
			</Container>
		</Box>
	);
};

export default Hero;
