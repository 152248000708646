import { Box, Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import vector1 from "../../../assets/vector1.png";
import vector2 from "../../../assets/vector2.png";
import vector5 from "../../../assets/vector5.png";
import vector6 from "../../../assets/vector6.png";
import arrowsimg from "../../../assets/arrows.png";
import rightsecwhat from "../../../assets/whatwedo_rightsection.png";

const Whatwedo = () => {
	return (
		<Container
			maxWidth="xl"
			className="z-10 flex flex-col md:flex-row py-20 md:py-0 min-h-[755px] gap-4 md:gap-0 p-0"
		>
			<Stack className="md:py-16 px-3 flex-row gap-2 md:justify-center place-items-center md:place-items-start md:min-w-[140px]">
				<span className="flex flex-row text-sm md:text-[14px] font-semibold place-items-center gap-2">
					<img
						src={arrowsimg}
						className="w-2 h-2"
						alt=""
					></img>
					What we do
				</span>
			</Stack>
			<Stack className="md:flex-1 flex-col md:py-[140px] gap-8 justify-start place-items-center flex-wrap md:border-x-2 md:border-x-[#DFDFDF] overflow-x-auto scrollbar-hide">
				<Stack className="px-3 md:px-8 gap-4 max-w-full">
					<Typography className="text-3xl lg:text-[40px] leading-8 lg:leading-[45px] font-semibold">
						Rapid prototyping & design
					</Typography>
					<Typography className="text-base text-[#737373] leading-5">
						The most common industry standards are embedded in Silo App’s
						algorithms, allowing automated design and on the spot generation of
						Layout, Flow Diagram, BoQ and Quotation.
					</Typography>
				</Stack>
				<Stack className="hidden md:flex flex-row md:flex-col gap-4 px-8 overflow-x-auto scrollbar-hide">
					<div className="flex flex-row gap-4">
						<Stack className="min-w-[330px] md:w-1/2 md:min-w-0 p-6 min-h-[155px] rounded-3xl bg-[#FFF8EB] gap-2 justify-start">
							<Stack className="relative w-6 h-6">
								<img
									src={vector1}
									alt=""
								/>
								<Box className="flex place-items-center justify-center bg-[#6AC230] absolute right-[-5px] top-[-5px] text-[10px] text-center rounded-full h-3 w-3 p-[2px] text-white font-medium">
									A
								</Box>
							</Stack>
							<Stack>
								<Typography className="text-lg font-medium">
									Sales Tool
								</Typography>
								<span className="text-base text-[#737373]">
									SiloApp is a Sales & Quotation Tool for Grain Handling,
									Processing and Storage Industry.
								</span>
							</Stack>
						</Stack>
						<Stack className="min-w-[330px] md:w-1/2 md:min-w-0 p-6 min-h-[155px] rounded-3xl bg-[#FFF8EB] gap-2 justify-start">
							<Stack className="relative w-6 h-6">
								<img
									src={vector2}
									alt=""
								/>
								<Box className="flex place-items-center justify-center bg-[#6AC230] absolute right-[-5px] top-[-5px] text-[10px] text-center rounded-full h-3 w-3 p-[2px] text-white font-medium">
									B
								</Box>
							</Stack>
							<Stack>
								<Typography className="text-lg font-medium">
									Design Tool
								</Typography>
								<span className="text-base text-[#737373]">
									SiloApp is an easy to use Design Tool for Farmers and
									Professionals.
								</span>
							</Stack>
						</Stack>
					</div>
					<div className="flex flex-row gap-4">
						<Stack className="min-w-[330px] md:w-1/2 md:min-w-0 p-6 min-h-[155px] rounded-3xl bg-[#FFF8EB] gap-2 justify-start">
							<Stack className="relative w-6 h-6">
								<img
									src={vector5}
									alt=""
								/>
								<Box className="flex place-items-center justify-center bg-[#6AC230] absolute right-[-5px] top-[-5px] text-[10px] text-center rounded-full h-3 w-3 p-[2px] text-white font-medium">
									C
								</Box>
							</Stack>
							<Stack>
								<Typography className="text-lg font-medium">
									Marketplace
								</Typography>
								<span className="text-base text-[#737373]">
									SiloApp is a Marketplace for all stakeholders in the Grain
									Business.
								</span>
							</Stack>
						</Stack>
						<Stack className="min-w-[330px] md:w-1/2 md:min-w-0 p-6 h-auto rounded-2xl bg-[#FFF8EB] gap-2 justify-start">
							<Stack className="relative w-6 h-6">
								<img
									src={vector6}
									alt=""
								/>
								<Box className="flex place-items-center justify-center bg-[#6AC230] absolute right-[-5px] top-[-5px] text-[10px] text-center rounded-full h-3 w-3 p-[2px] text-white font-medium">
									D
								</Box>
							</Stack>
							<Stack>
								<Typography className="text-lg font-medium">
									Learning Center
								</Typography>
								<span className="text-base text-[#737373]">
									Learn the industry standards from our information center.
								</span>
							</Stack>
						</Stack>
					</div>
				</Stack>
			</Stack>
			<Stack className="md:hidden flex flex-row md:flex-col gap-2 px-3 mt-4 overflow-x-auto scrollbar-hide">
				<div className="flex flex-row gap-4">
					<Stack className="min-w-[330px] md:w-1/2 md:min-w-0 p-4 h-auto rounded-2xl bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector1}
								alt=""
							/>
							<Box className="flex place-items-center justify-center bg-[#6AC230] absolute right-[-5px] top-[-5px] text-[10px] text-center rounded-full h-3 w-3 p-[2px] text-white font-medium">
								A
							</Box>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">
								Sales Tool
							</Typography>
							<span className="text-base text-[#737373]">
								SiloApp is a Sales & Quotation Tool for Grain Handling,
								Processing and Storage Industry.
							</span>
						</Stack>
					</Stack>
					<Stack className="min-w-[330px] md:w-1/2 md:min-w-0 p-4 h-auto rounded-2xl bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector2}
								alt=""
							/>
							<Box className="flex place-items-center justify-center bg-[#6AC230] absolute right-[-5px] top-[-5px] text-[10px] text-center rounded-full h-3 w-3 p-[2px] text-white font-medium">
								B
							</Box>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">
								Design Tool
							</Typography>
							<span className="text-base text-[#737373]">
								SiloApp is an easy to use Design Tool for Farmers and
								Professionals.
							</span>
						</Stack>
					</Stack>
				</div>
				<div className="flex flex-row gap-4">
					<Stack className="min-w-[330px] md:w-1/2 md:min-w-0 p-4 h-auto rounded-2xl bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector5}
								alt=""
							/>
							<Box className="flex place-items-center justify-center bg-[#6AC230] absolute right-[-5px] top-[-5px] text-[10px] text-center rounded-full h-3 w-3 p-[2px] text-white font-medium">
								C
							</Box>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">
								Marketplace
							</Typography>
							<span className="text-base text-[#737373]">
								SiloApp is a Marketplace for all stakeholders in the Grain
								Business.
							</span>
						</Stack>
					</Stack>
					<Stack className="min-w-[330px] md:w-1/2 md:min-w-0 p-4 h-auto rounded-2xl bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector6}
								alt=""
							/>
							<Box className="flex place-items-center justify-center bg-[#6AC230] absolute right-[-5px] top-[-5px] text-[10px] text-center rounded-full h-3 w-3 p-[2px] text-white font-medium">
								D
							</Box>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">
								Learning Center
							</Typography>
							<span className="text-base text-[#737373]">
								Learn the industry standards from our information center.
							</span>
						</Stack>
					</Stack>
				</div>
			</Stack>
			<Stack className="min-w-full md:min-w-[270px] sm:max-w-[270px] md:max-w-[270px] lg:min-w-[420px] place-items-center flex md:bg-whatwedo-bg bg-contain lg:bg-cover bg-no-repeat bg-center isolate bg-blend-hard-light ">
				<img
					src={rightsecwhat}
					alt=""
					className="md:hidden w-full object-contain md:object-cover min-h-[400px] max-h-[450px]"
				/>
			</Stack>
		</Container>
	);
};

export default Whatwedo;
