import { atom, useRecoilState } from "recoil";

const navState = atom({
	key: "isMenuState",
	default: false,
});

const useMobileMenu = () => {
	const [mobileMenu, setMobileMenu] = useRecoilState(navState);
	const open = () => {
		setMobileMenu(true);
		document.querySelector("body").classList.add("hide-scroll");
		return;
	};
	const close = () => {
		setMobileMenu(false);
		document.querySelector("body").classList.remove("hide-scroll");
		return;
	};
	return { mobileMenu, setMobileMenu, open, close };
};

export default useMobileMenu;
