import { Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import vector13 from "../../../assets/vector13.png";
import vector14 from "../../../assets/vector14.png";
import vector15 from "../../../assets/vector15.png";
import vector16 from "../../../assets/vector16.png";
import vector17 from "../../../assets/vector17.png";
import vector18 from "../../../assets/vector18.png";
import forfarmerssec from "../../../assets/forfarmers_rightsection.png";

const ForFarmers = () => {
	return (
		<Container
			maxWidth="xl"
			className="flex flex-col md:flex-row py-20 md:py-0 pb-0 min-h-[650px] gap-4 md:gap-0 px-0"
		>
			<Stack className="md:py-14 px-3 flex-row gap-2 place-items-center md:place-items-start md:min-w-[140px]"></Stack>
			<Stack className="md:flex-1 md:py-20 gap-4 flex-wrap md:border-x-2 md:border-x-[#DFDFDF]">
				<Stack className="flex-row px-3 md:px-8 gap-4 place-items-center">
					<Typography className="bg-[#F4F4F4] rounded-full w-12 h-12 flex justify-center place-items-center">
						🚜
					</Typography>
					<Typography className="font-['Arimo'] text-3xl lg:text-[40px] leading-10 lg:leading-[45px] font-semibold">
						For Farmers
					</Typography>
				</Stack>
				<Stack className="grid grid-cols-2 lg:grid-cols-3 grid-row-1 flex-wrap gap-4 px-3 md:px-8 mt-6 overflow-x-auto scrollbar-hide">
					<Stack className="p-6 rounded-[20px] bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector13}
								alt=""
							/>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">DIY</Typography>
							<span className="text-xs text-[#737373]">
								Design your own Silo Plant in a simple and fun way.
							</span>
						</Stack>
					</Stack>
					<Stack className="p-6 h-auto rounded-[20px] bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector14}
								alt=""
							/>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">Inquiries</Typography>
							<span className="text-xs text-[#737373]">
								Send Inquiries to suppliers of your choice.
							</span>
						</Stack>
					</Stack>
					<Stack className="p-6 h-auto rounded-[20px] bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector15}
								alt=""
							/>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">Resources</Typography>
							<span className="text-xs text-[#737373]">
								Manage your resources and choose the best supplier.
							</span>
						</Stack>
					</Stack>
					<Stack className="p-6 h-auto rounded-[20px] bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector16}
								alt=""
							/>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">
								Customization
							</Typography>
							<span className="text-xs text-[#737373]">
								Update your project continuously with a click.
							</span>
						</Stack>
					</Stack>
					<Stack className="p-6 h-auto rounded-[20px] bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector17}
								alt=""
							/>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">
								Information
							</Typography>
							<span className="text-xs text-[#737373]">
								Stay informed about the best practice and standards.
							</span>
						</Stack>
					</Stack>
					<Stack className="p-6 h-auto rounded-[20px] bg-[#FFF8EB] gap-2">
						<Stack className="relative w-6 h-6">
							<img
								src={vector18}
								alt=""
							/>
						</Stack>
						<Stack>
							<Typography className="text-lg font-medium">Support</Typography>
							<span className="text-xs text-[#737373]">
								Our specialists are ready to offer you consultancy.
							</span>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<Stack className="min-w-full md:min-w-[270px] sm:max-w-[270px] md:max-w-[270px] lg:max-w-[420px] justify-center">
				<img
					src={forfarmerssec}
					alt=""
					className="object-cover h-[400px] md:h-full"
				/>
			</Stack>
		</Container>
	);
};

export default ForFarmers;
