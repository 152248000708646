export const useValidator = () => {
	const validate_text = (text) => {
		if (text === undefined) {
			return false;
		}
		text = text.trim();
		if (text.length > 0) {
			return true;
		}
		return false;
	};
	const validate_phone = (text) => {
		let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
		if (text.match(phoneno)) {
			return true;
		} else {
			return false;
		}
	};
	const validate_email = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};
	return { validate_email, validate_text, validate_phone };
};
