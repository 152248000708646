import { Box } from "@mui/material";
import { SnackbarProvider } from "notistack";
import Footer from "../homepage/components/Footer";
import FreeTry from "../homepage/components/FreeTry";
import MobileMenu from "../MobileMenu";
import GeneralFAQ from "./components/GeneralFAQ";
import Hero from "./components/Hero";

const FAQ = () => {
	return (
		<SnackbarProvider maxSnack={3}>
			<MobileMenu />
			<Box className={`relative min-h-screen overflow-hidden`}>
				<Hero />
				<GeneralFAQ />
				<FreeTry />
				<Footer />
			</Box>
		</SnackbarProvider>
	);
};

export default FAQ;
