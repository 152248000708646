import { ArrowDropUp } from "@mui/icons-material";
import {
	Box,
	Container,
	IconButton,
	List,
	ListItem,
	ListItemText,
} from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import siloapplogo2 from "../../../assets/siloapp_logo2.svg";
import vector23 from "../../../assets/vector23.png";
import vector24 from "../../../assets/vector24.png";
import vector26 from "../../../assets/vector26.png";
import vector27 from "../../../assets/vector27.png";
import vector28 from "../../../assets/vector28.png";
import { useSelection } from "../../../hooks/useSelected";

export const scrollTop = () => {
	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});
};
const Footer = () => {
	const { setBookDemo, setAskConsultant } = useSelection();
	return (
		<Container
			maxWidth="xl"
			className="relative flex flex-col md:flex-row py-20 pb-10 md:py-0 md:pb-0 gap-4 md:gap-0 px-0"
		>
			<IconButton
				onClick={scrollTop}
				className="z-50 left-1/2 -translate-x-1/2 md:left-[calc(100%-270px)] lg:left-[calc(100%-420px)] top-[-50px] w-20 h-20 rounded-full shadow-md absolute bg-white hover:bg-[#5ba828] hover:shadow-md group"
			>
				<ArrowDropUp className="text-black group-hover:text-white" />
			</IconButton>
			<Stack className="w-full md:w-[140px] place-items-center md:py-20 pb-0 px-6">
				<img
					src={siloapplogo2}
					alt="Homepage Logo"
					
				/>
			</Stack>
			<Stack className="md:flex-1 md:flex-row px-3 md:px-8 justify-center md:justify-evenly md:place-items-start py-6 md:pb-0 md:border-x-2 md:border-x-[#DFDFDF] gap-4">
				<List
					dense
					className="flex flex-col justify-start text-center md:text-left my-4"
					subheader={
						<p className="mb-2 text-[13px] font-medium text-[#737373]">Menu</p>
					}
				>
					<ListItem className="md:p-0">
						<ListItemText className="text-center md:text-left hover:underline cursor-pointer">
							<Link
								onClick={scrollTop}
								to={"/"}
							>
								<span className="text-[16px] font-bold">Home</span>
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem className="md:p-0">
						<ListItemText className="text-center md:text-left hover:underline cursor-pointer">
							<Link
								onClick={scrollTop}
								to={"/how-it-works"}
							>
								<span className="text-[16px] font-bold">How it works</span>
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem className="md:p-0">
						<ListItemText className="text-center md:text-left hover:underline cursor-pointer">
							<Link
								onClick={scrollTop}
								to={"/whysiloapp"}
							>
								<span className="text-[16px] font-bold">Why SiloApp</span>
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem className="md:p-0">
						<ListItemText className="text-center md:text-left hover:underline cursor-pointer">
							<Link
								onClick={scrollTop}
								to={"/contact"}
							>
								<span className="text-[16px] font-bold">Contact</span>
							</Link>
						</ListItemText>
					</ListItem>
				</List>
				<List
					dense
					className="flex flex-col justify-start text-center md:text-left my-4"
					subheader={
						<p className="mb-2 text-[13px] font-medium text-[#737373]">
							Resources
						</p>
					}
				>
					<ListItem className="md:p-0">
						<ListItemText className="text-center md:text-left hover:underline cursor-pointer">
							<Link
								onClick={scrollTop}
								to={"/pricing"}
							>
								<span className="text-[16px] font-bold">Pricing</span>
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem className="md:p-0">
						<ListItemText className="text-center md:text-left hover:underline cursor-pointer">
							<Link
								onClick={scrollTop}
								to={"/FAQ"}
							>
								<span className="text-[16px] font-bold">FAQ</span>
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem className="md:p-0">
						<ListItemText className="text-center md:text-left hover:underline cursor-pointer">
							<Link
								onClick={() => {
									scrollTop();
									setAskConsultant();
								}}
								to={"/contact"}
							>
								<span className="text-[16px] font-bold">Ask a Consultant</span>
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem className="md:p-0">
						<ListItemText className="text-center md:text-left hover:underline cursor-pointer">
							<Link
								onClick={() => {
									scrollTop();
									setBookDemo();
								}}
								to={"/contact"}
							>
								<span className="text-[16px] font-bold">Ask for Demo</span>
							</Link>
						</ListItemText>
					</ListItem>
				</List>
				<List
					dense
					className="h-full flex flex-col justify-start md:text-left text-center my-4"
					subheader={
						<p className="mb-2 text-[13px] font-medium text-[#737373]">Legal</p>
					}
				>
					<ListItem className="md:p-0">
						<ListItemText className="text-center md:text-left hover:underline cursor-pointer">
							<Link
								onClick={scrollTop}
								to={"/terms-and-conditions"}
							>
								<span className="text-[16px] font-bold">
									Terms and Conditions
								</span>
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem className="md:p-0">
						<ListItemText className="text-center md:text-left hover:underline cursor-pointer">
							<Link
								onClick={scrollTop}
								to={"/privacy-policy"}
							>
								<span className="text-[16px] font-bold">Privacy Policy</span>
							</Link>
						</ListItemText>
					</ListItem>
					<ListItem className="md:p-0">
						<ListItemText className="text-center md:text-left hover:underline cursor-pointer">
							<Link
								onClick={scrollTop}
								to={"/terms-of-service"}
							>
								<span className="text-[16px] font-bold">Terms of Service</span>
							</Link>
						</ListItemText>
					</ListItem>
				</List>
			</Stack>
			<Stack className="flex min-w-full md:min-w-[270px] sm:max-w-[270px] md:max-w-[270px] lg:min-w-[420px] lg:max-w-[420px] justify-center md:place-content-start md:py-10">
				<Stack className="flex-row gap-8 pb-4 justify-center place-items-center">
					<Box className="hover:bg-[#81ec3a] p-2 rounded-full">
						<a
							href="https://ro.linkedin.com/company/siloapp?original_referer=https%3A%2F%2Fwww.google.ro%2F"
							target={"_blank"}
							rel="noreferrer"
						>
							<img
								src={vector26}
								alt=""
								className="h-4 w-4"
							/>
						</a>
					</Box>
					<Box className="hover:bg-[#81ec3a] p-2 rounded-full">
						<a
							href="https://mobile.twitter.com/siloapp1"
							target={"_blank"}
							rel="noreferrer"
						>
							<img
								src={vector27}
								alt=""
								className="h-4 w-4"
							/>
						</a>
					</Box>
					<Box className="hover:bg-[#81ec3a] p-2 rounded-full">
						<a
							href="https://youtube.com/@siloapp1057"
							target={"_blank"}
							rel="noreferrer"
						>
							<img
								src={vector28}
								alt=""
								className="h-4 w-4"
							/>
						</a>
					</Box>
				</Stack>
				<Stack className="gap-2 px-4 justify-center place-items-center md:justify-start md:place-items-start md:mx-auto">
					<Box className="flex gap-2 place-items-center">
						<img
							src={vector23}
							alt=""
							className="h-3 w-3"
						/>
						<span className="text-base font-light">+40 729 016 150</span>
					</Box>
					<Box className="flex gap-2 place-items-center">
						<img
							src={vector24}
							alt=""
							className="h-3 w-3"
						/>
						<span className="text-base font-light">
							adrian@agritech-center.com
						</span>
					</Box>
					<Box>
						<span className="text-xs font-medium text-[#737373]">
							© Silo Software Consulting SRL
						</span>
					</Box>
				</Stack>
			</Stack>
		</Container>
	);
};

export default Footer;
