import { Box } from "@mui/system";
import { SnackbarProvider } from "notistack";
import Footer from "../homepage/components/Footer";
import SeeHowItWorks from "../homepage/components/SeeHowItWorks";
import MobileMenu from "../MobileMenu";
import AddAndRequestSingle from "./components/AddAndRequestSingle";
import CustomizeAndEdit from "./components/CustomizeAndEdit";
import DirectFAQ from "./components/DirectFAQ";
import Hero from "./components/Hero";
import MarketPlaceRequest from "./components/MarketPlaceRequest";
import StartAndOrganize from "./components/StartAndOrganize.jsx";

const HowItWorks = () => {
	return (
		<SnackbarProvider maxSnack={3}>
			<MobileMenu />
			<Box className={`relative min-h-screen overflow-hidden`}>
				<Hero />
				<StartAndOrganize />
				<CustomizeAndEdit />
				<AddAndRequestSingle />
				<MarketPlaceRequest />
				<DirectFAQ />
				<SeeHowItWorks />
				<Footer />
			</Box>
		</SnackbarProvider>
	);
};

export default HowItWorks;
