import { Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useRef } from "react";
import vector29 from "../../../assets/vector29.png";

const StartPrototyping = () => {
	const centerRef = useRef();
	const resizeCenter = () => {
		const center = centerRef.current;
		if (center == null || center === undefined) return;
		const layouts = document.querySelector("#layoutsW");
		layouts.setAttribute(
			"style",
			"width: " + center.getBoundingClientRect().width + "px"
		);
	};
	useEffect(() => {
		resizeCenter();
	}, [centerRef]);
	window.addEventListener("resize", resizeCenter);
	window.addEventListener("scroll", resizeCenter);
	return (
		<Stack
			id="start"
			className="relative p-3 px-0 md:p-0 justify-center h-[500px] md:h-[450px] bg-black gap-4 bg-proto-bg bg-center bg-no-repeat"
		>
			<div className="bg-[rgba(0,0,0,0.5)] absolute top-0 z-0 h-full w-full"></div>
			<Container maxWidth="xl" className="flex-1 z-10 flex flex-col md:flex-row h-full p-0">
				<Stack className="hidden md:flex md:py-14 px-3 flex-row gap-2 md:justify-center place-items-center md:place-items-end md:w-[140px]">
					<span className="flex flex-col text-sm md:text-[14px] font-medium place-items-start gap-2 text-white leading-4">
						<img
							src={vector29}
							className="w-2 h-2"
							alt=""
						></img>
						Design adds value faster than it adds costs.
					</span>
				</Stack>
				<Stack
					ref={centerRef}
					className="px-3 md:px-8 flex-1 md:border-x-2 border-x-[#DFDFDF] gap-4 justify-center"
				>
					<Stack>
						<Typography className="font-['Arimo'] text-3xl lg:text-[40px] leading-8 lg:leading-[45px] font-semibold text-white">
							Start prototyping & designing now
						</Typography>
					</Stack>
					<Stack>
						<Typography className="text-xl text-gray-300 font-light leading-5">
							We are proud of the innovative technology we offer to help farmers
							and professionals best to do their most important work.
						</Typography>
					</Stack>
					<Stack className="flex-row gap-2 mt-2">
						<a
							href="https://dev.thesiloapp.com/signup"
							className="flex justify-center py-2 w-1/2 md:w-32 bg-[#6AC230] hover:bg-[#5ba828] rounded-full "
						>
							<span className="text-base font-bold text-white">Sign Up</span>
						</a>

						<a
							href="https://dev.thesiloapp.com/sign-in"
							className="flex justify-center place-items-center py-2 w-1/2 md:w-32 bg-white rounded-full hover:bg-gray-200"
						>
							<span className="text-base font-bold text-black">Login</span>
						</a>
					</Stack>
				</Stack>
				<Stack className="hidden min-w-[270px] sm:max-w-[270px] md:max-w-[270px] lg:min-w-[420px] md:flex"></Stack>
			</Container>
		</Stack>
	);
};

export default StartPrototyping;
