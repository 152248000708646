import siloapplogo from "../../../assets/siloapp_logo.svg";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import useMobileMenu from "../../../hooks/useMobileMenu";
import { useSelection } from "../../../hooks/useSelected";
import { Menu } from "@mui/icons-material";

const HeroMobile = ({ setErrorMsg }) => {
	const { open } = useMobileMenu();
	const { setSelection, isSelected } = useSelection(setErrorMsg);
	return (
		<Stack className="flex-1 grid grid-rows-layout h-full md:hidden">
			<Stack className="z-10 flex py-8 pt-9 px-6 flex-row place-items-center h-[150px] border-b-2 border-b-[rgba(255,255,255,0.3)]">
				<Box>
					<img
						src={siloapplogo}
						alt="Homepage Logo"
					/>
				</Box>
				<Box className="flex flex-1 justify-end h-14">
					<IconButton
						onClick={open}
						className="rounded-full w-24 hover:bg-gray-100 bg-white group"
					>
						<Menu className="text-xl group-hover:text-black" />
					</IconButton>
				</Box>
			</Stack>
			<Stack className="z-10 justify-center place-items-start pb-6 pt-4 px-4 gap-4 bg-white">
				<div className="flex py-1 rounded-full justify-center">
					<span className="text-[#6AC230] text-[14px]">Contact</span>
				</div>
				<Typography className="w-[70%] text-[40px] leading-[46px] lg:text-[75px] lg:leading-[75px] font-['Arimo'] text-black font-semibold mb-2">
					Message us or request a demo
				</Typography>
				<Typography className="text-[19px] text-black font-light leading-5">
					Please use this form to get in touch, or reach out to a specific team
					using the contact directory below. We look forward to hearing from
					you.
				</Typography>
				<Stack className="w-full gap-4">
					<hr className="w-full my-4" />
					<Typography className="w-full text-base font-bold text-center">
						I want to:
					</Typography>
					<Stack className="flex flex-1 flex-row flex-wrap gap-4 p-0 md:px-3 md:py-3 justify-center place-items-center">
						<Stack
							onClick={setSelection}
							className={`border-2 border-[#DFDFDF] ${
								isSelected(1) && "border-[#5ba828]"
							} hover:border-[#6AC238] rounded-2xl justify-center`}
						>
							<Button
								id="1"
								className={`min-w-[150px] p-3 py-2 text-black text-base capitalize font-semibold text-center h-auto rounded-2xl bg-white gap-2 hover:text-[#6AC238]
											${isSelected(1) && "text-[#5ba828]"}
										`}
							>
								Say hello
							</Button>
						</Stack>
						<Stack
							onClick={setSelection}
							className={`border-2 border-[#DFDFDF] ${
								isSelected(2) && "border-[#5ba828]"
							} hover:border-[#6AC238] rounded-2xl justify-center`}
						>
							<Button
								id="2"
								className={`min-w-[150px] p-3 py-2 text-black text-base capitalize font-semibold text-center h-auto rounded-2xl bg-white gap-2 hover:text-[#6AC238]
											${isSelected(2) && "text-[#5ba828]"}
										`}
							>
								Book a demo
							</Button>
						</Stack>
						<Stack
							onClick={setSelection}
							className={`border-2 border-[#DFDFDF] ${
								isSelected(3) && "border-[#5ba828]"
							} hover:border-[#6AC238] rounded-2xl justify-center`}
						>
							<Button
								id="3"
								className={`min-w-[150px] p-3 py-2 text-black text-base capitalize font-semibold text-center h-auto rounded-2xl bg-white gap-2 hover:text-[#6AC238]
											${isSelected(3) && "text-[#5ba828]"}
										`}
							>
								Ask a Consultant
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

const Hero = ({ setErrorMsg }) => {
	const { open } = useMobileMenu();
	const { setSelection, isSelected, selection } = useSelection(setErrorMsg);
	return (
		<Box className="flex flex-col h-[700px] md:min-h-[550px] lg:min-h-0 lg:h-[550px] relative overflow-hidden md:overflow-visible bg-contact-bg bg-center bg-cover bg-no-repeat">
			<div className="bg-[rgba(0,0,0,0.4)] absolute top-0 z-0 h-full w-full"></div>
			<HeroMobile setErrorMsg={setErrorMsg} />
			<Container
				maxWidth="xl"
				className="relative z-20 flex-1 flex-row h-full hidden border-b-2 border-b-[rgba(255,255,255,0.3)] md:flex p-0"
			>
				<IconButton className="left-[140px] -translate-x-1/2 md:left-[calc(100%-270px)] lg:left-[calc(100%-420px)] bottom-[-40px] w-20 h-20 rounded-full shadow-md absolute bg-white hover:bg-gray-200 hover:shadow-md group border-2 border-[#DFDFDF] z-30">
					{(() => {
						switch (selection) {
							case "1":
								return <a href="#start">👋</a>;
							case "2":
								return <a href="#start">📅</a>;
							case "3":
								return <a href="#start">❓</a>;
							default:
								return <></>;
						}
					})()}
				</IconButton>
				<Stack className="w-[140px] place-items-center justify-center">
					<Box className="flex justify-center p-2 h-[130px]">
						<img
							src={siloapplogo}
							alt="Homepage Logo"
							className="object-contain"
						/>
					</Box>
					<div className="flex flex-1 p-3 w-full justify-center bg-white">
						<span className="text-[#6AC230] text-[14px] font-medium">
							Contact
						</span>
					</div>
				</Stack>
				<Stack className="flex-1 place-items-center justify-start px-0 gap-3 border-x-2 border-x-[rgba(255,255,255,0.3)]">
					<div className="w-full flex flex-col p-0 h-full">
						<Stack className="hidden lg:flex h-[130px] flex-row flex-wrap place-items-center p-2 px-4 justify-start gap-8">
							<div>
								<div className="text-center hover:underline cursor-pointer text-white">
									<Link to={"/"}>
										<span className="text-base font-bold">Home</span>
									</Link>
								</div>
							</div>
							<div>
								<div className="text-center hover:underline cursor-pointer text-white">
									<Link to={"/how-it-works"}>
										<span className="text-base font-bold">How it works</span>
									</Link>
								</div>
							</div>
							<div>
								<div className="text-center hover:underline cursor-pointer text-white">
									<Link to={"/whysiloapp"}>
										<span className="text-base font-bold">Why SiloApp</span>
									</Link>
								</div>
							</div>
							<div>
								<div className="text-center hover:underline cursor-pointer text-white">
									<Link to={"/FAQ"}>
										<span className="text-base font-bold">FAQ</span>
									</Link>
								</div>
							</div>
							<div>
								<div className="text-center hover:underline cursor-pointer text-[#6AC230]">
									<Link to={"/contact"}>
										<span className="text-base font-bold">Contact</span>
									</Link>
								</div>
							</div>
						</Stack>
						<Stack className="flex-1 gap-4 px-4 md:gap-4 mt-28 md:mt-[130px] lg:mt-0 justify-center bg-white">
							<Typography className="w-[70%] text-[40px] leading-[46px] font-['Arimo'] text-black font-medium mb-2">
								Message us or request a demo
							</Typography>
							<Typography className="text-xs md:text-[19px] text-black font-light leading-5">
								Please use this form to get in touch, or reach out to a specific
								team using the contact directory below. We look forward to
								hearing from you.
							</Typography>
							<hr className="my-4" />
							<Stack className="flex-col flex-wrap lg:flex-row place-items-center gap-4">
								<Typography className="text-base font-bold px-3 lg:px-0 lg:text-center">
									I want to:
								</Typography>
								<Stack className="flex md:place-items-start md:justify-center lg:justify-start flex-row flex-wrap gap-4 py-3 overflow-x-auto scrollbar-hide">
									<Stack
										id="1"
										onClick={setSelection}
										className={`border-2 border-[#DFDFDF] ${
											isSelected(1) && "border-[#5ba828]"
										} hover:border-[#6AC238] rounded-2xl justify-center`}
									>
										<Button
											id="1"
											className={`min-w-[150px] p-3 py-2 text-black text-base capitalize font-semibold text-center h-auto rounded-2xl bg-white gap-2 hover:text-[#6AC238]
											${isSelected(1) && "text-[#5ba828]"}
										`}
										>
											Say hello
										</Button>
									</Stack>
									<Stack
										id="2"
										onClick={setSelection}
										className={`border-2 border-[#DFDFDF] ${
											isSelected(2) && "border-[#5ba828]"
										} hover:border-[#6AC238] rounded-2xl justify-center`}
									>
										<Button
											id="2"
											className={`min-w-[150px] p-3 py-2 text-black text-base capitalize font-semibold text-center h-auto rounded-2xl bg-white gap-2 hover:text-[#6AC238]
											${isSelected(2) && "text-[#5ba828]"}
										`}
										>
											Book a demo
										</Button>
									</Stack>
									<Stack
										id="3"
										onClick={setSelection}
										className={`border-2 border-[#DFDFDF] ${
											isSelected(3) && "border-[#5ba828]"
										} hover:border-[#6AC238] rounded-2xl justify-center`}
									>
										<Button
											id="3"
											className={`min-w-[150px] p-3 py-2 text-black text-base capitalize font-semibold text-center h-auto rounded-2xl bg-white gap-2 hover:text-[#6AC238]
											${isSelected(3) && "text-[#5ba828]"}
										`}
										>
											Ask a Consultant
										</Button>
									</Stack>
								</Stack>
							</Stack>
						</Stack>
					</div>
				</Stack>
				<Stack className="w-full md:min-w-[270px] md:max-w-[270px] lg:max-w-[420px] justify-center">
					<Box className="flex justify-end place-items-center py-6 pr-6 h-[135px] lg:hidden">
						<IconButton
							onClick={open}
							className="rounded-full h-10 px-10 hover:bg-gray-100 bg-white group"
						>
							<Menu className="text-base group-hover:text-black" />
						</IconButton>
					</Box>
					<Stack className="justify-center place-items-center flex-row gap-4 px-12 py-6 hidden lg:flex h-[135px]">
						<a
							href="https://dev.thesiloapp.com/signup"
							className="flex place-items-center w-[137px] capitalize h-10 bg-[#6AC230] hover:bg-[#5ba828] rounded-full text-center"
						>
							<span className="w-full flex justify-center place-items-center text-[16px] font-bold text-white">
								Sign Up
							</span>
						</a>
						<a
							href="https://dev.thesiloapp.com/sign-in"
							className="flex place-items-center w-auto min-w-[120px] h-10 px-6 bg-white rounded-full hover:bg-gray-200 capitalize"
						>
							<span className="w-full flex justify-center place-items-center text-[16px] font-bold text-black">
								Login
							</span>
						</a>
					</Stack>
					<Stack className="flex-1"></Stack>
				</Stack>
			</Container>
		</Box>
	);
};

export default Hero;
