import { PlayArrow } from "@mui/icons-material";
import { Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import vector29 from "../../../assets/vector29.png";
import rightsec_see from "../../../assets/playimg_section.png";

const SeeHowItWorks = () => {
	return (
		<Stack className="relative p-3 px-0 md:p-0 justify-center h-[500px] md:min-h-[450px] md:h-auto gap-4 md:gap-0 bg-howitworks-bg bg-center bg-cover bg-no-repeat">
			<div className="bg-[rgba(0,0,0,0.4)] absolute top-0 z-0 h-full w-full"></div>
			<Container
				maxWidth="xl"
				className="flex-1 z-10 flex flex-col md:flex-row h-full p-0 justify-center"
			>
				<Stack className="hidden md:flex md:py-10 px-3 flex-row gap-2 md:justify-center place-items-center md:place-items-end md:max-w-[140px]">
					<span className="flex flex-col text-sm md:text-[14px] font-semibold place-items-start gap-2 text-white leading-4">
						<img
							src={vector29}
							className="w-2 h-2"
							alt=""
						></img>
						I was able to provide professional drawings on the spot, during my
						first visit to the customer.
					</span>
				</Stack>
				<Stack className="md:flex-1 md:py-20 px-3 md:px-8 justify-center md:border-x-2 md:border-x-[#DFDFDF]">
					<Stack>
						<Typography className="font-['Arimo'] text-3xl lg:text-[40px] leading-10 lg:leading-[45px] font-semibold text-white">
							See how it works for yourself
						</Typography>
					</Stack>
					<Stack className="mt-2">
						<Typography className="text-sm text-gray-300 font-light leading-5">
							We are proud of the innovative technology we offer to help farmers
							and professionals best to do their most important work.
						</Typography>
					</Stack>
					<Stack className="flex-row gap-2 mt-6 max-w-[350px]">
						<a
							rel="noreferrer"
							target={"_blank"}
							href="https://youtu.be/JkIS3JlwUZg"
							className="py-3 w-1/2 bg-white hover:bg-gray-300 rounded-full capitalize text-center"
						>
							<span className="flex place-items-center justify-center text-[14px] font-bold text-black gap-2">
								<PlayArrow className="text-xs" /> Play video
							</span>
						</a>
					</Stack>
				</Stack>
				<Stack className="hidden md:flex min-w-[270px] sm:max-w-[270px] md:max-w-[270px] lg:min-w-[420px] lg:max-w-[420px] justify-center">
					<img
						src={rightsec_see}
						alt=""
						className="w-full object-cover md:object-fill md:h-[50%] lg:h-[80%]"
					/>
				</Stack>
			</Container>
		</Stack>
	);
};

export default SeeHowItWorks;
