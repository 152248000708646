import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useValidator } from "../../../hooks/useValidator";

const Field = ({
	type,
	name,
	setRef,
	placeholder,
	required,
	setErrorMsg,
	textArea,
}) => {
	const inputRef = useRef(null);
	const [value, setValue] = useState("");
	const [error, setError] = useState(false);
	const [active, setActive] = useState(false);
	const [border, setBorder] = useState("border-none");
	const { validate_text, validate_phone, validate_email } = useValidator();
	const validate_type = useCallback(
		(type, text) => {
			switch (type) {
				case "text":
					if (!validate_text(text)) {
						setError(true);
						setBorder("border-error");
						setErrorMsg("Invalid field, please provide the correct data");
						return false;
					}
					break;
				case "email":
					if (!validate_email(text)) {
						setError(true);
						setBorder("border-error");
						setErrorMsg(
							"Invalid email, please provide the correct email address"
						);
						return false;
					}
					break;
				case "number":
					if (!validate_phone(text)) {
						setError(true);
						setBorder("border-error");
						setErrorMsg(
							"Invalid phone number, please provide the correct phone number"
						);
						return false;
					}
					break;
				default:
					break;
			}
			return true;
		},
		[setErrorMsg, validate_email, validate_text, validate_phone]
	);
	const validate_changes = useCallback(() => {
		let text = inputRef.current.value;
		setValue(text);
		if (!textArea) text = text.trim();
		!active && setActive(true);
		if (required && text.length <= 0) {
			setError(true);
			setBorder("border-error");
			setErrorMsg("Please fill in the requested field");
			return;
		}
		if (required && text.length > 0) {
			if (validate_type(type, text)) {
				setError(false);
				setErrorMsg("");
				setBorder("border-okay");
			}
			return;
		}
		if (!required && text.length <= 0) {
			setBorder("border-none");
			return;
		}
		if (!required && text.length > 0) {
			if (validate_type(type, text)) {
				setError(false);
				setErrorMsg("");
				setBorder("border-okay");
			}
			return;
		}
		return;
	}, [textArea, active, type, required, setErrorMsg, validate_type]);
	useEffect(() => {
		setRef({
			name,
			error,
			active,
			setValue,
			setActive,
			validate_changes,
			input: inputRef.current,
		});
	}, [name, inputRef, error, active, setRef, validate_changes]);
	return textArea ? (
		<textarea
			name={name}
			value={value}
			ref={inputRef}
			placeholder={placeholder}
			onChange={validate_changes}
			className={`min-h-[200px] p-2 italic rounded-md outline-none ${
				active ? `${border}` : "border-none"
			}`}
		/>
	) : (
		<input
			name={name}
			type={type}
			value={value}
			ref={inputRef}
			maxLength={75}
			placeholder={placeholder}
			onChange={validate_changes}
			className={`w-full outline-none p-2 italic rounded-md ${
				active ? `${border}` : "border-none"
			}`}
		/>
	);
};

export default memo(Field);
