import { Container, List, ListItem, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import vector4 from "../../../assets/vector4.png";
import directfaqsec from "../../../assets/directfaq_bg.png";
import { Link } from "react-router-dom";
import arrowright from "../../../assets/arrowright.png";
import FAQItem from "../../FAQItem";
import { scrollTop } from "../../homepage/components/Footer";

const DirectFAQ = () => {
	return (
		<Container
			maxWidth="xl"
			className="flex flex-col md:flex-row py-20 md:py-0 pb-0 min-h-[550px] gap-4 md:gap-0 px-0"
		>
			<Stack className="md:py-14 px-3 flex-row gap-2 md:justify-center place-items-center md:place-items-start md:min-w-[140px]">
				<span className="flex flex-row text-sm md:text-[14px] font-bold place-items-center gap-2">
					<img
						src={vector4}
						className="w-2 h-2"
						alt=""
					></img>
					FAQ
				</span>
			</Stack>
			<Stack className="justify-center md:flex-1 md:py-20 gap-4 flex-wrap md:border-x-2 md:border-x-[#DFDFDF]">
				<Stack className="px-3 md:px-8 gap-2">
					<Typography className="text-3xl leading-8 lg:text-[40px] lg:leading-[46px] font-semibold text-black">
						Frequently asked questions
					</Typography>
				</Stack>
				<List>
					<ListItem className="px-3 md:px-8">
						<FAQItem
							question={"What is SiloApp?"}
							answer={
								"In a nutshell, SiloApp is the tool which helps you draft a 3D silo plant, flow diagram and BoQ in minutes, allowing you to send quotation requests to producers around the world!"
							}
						/>
					</ListItem>
					<ListItem className="px-3 md:px-8">
						<FAQItem
							question={"How do I set up my Account details?"}
							answer={
								"To view your account details go into the “Account” page present in the bottom left menu. Here you will find your Personal Details which are automatically filled with the information provided when creating your account, except for the phone number, as well as your Billing Address and Billing Details which you must complete manually."
							}
						/>
					</ListItem>
					<ListItem className="px-3 md:px-8">
						<FAQItem
							question={"Where can I view my quotation requests?"}
							answer={
								"To view your silo/single machines quotation requests go in the “Inquiries” page in the left menu."
							}
						/>
					</ListItem>
					<ListItem className="px-3 md:px-8">
						<FAQItem
							question={"How do I send a silo quotation request?"}
							answer={
								"When you have finished creating and editing the silo project, go to the 'Actions' menu at the top right of the screen and press the Quotation button. This will take you to the 'New Quotation Request' page where you can enter details for each project equipment, select products to be stored in your silo, enter additional information for the producer, and select the producer you want to send the request. When you are ready, hit the “Request quotation” button present in the bottom right of the screen and your request will be send and will appear in the tables from “Inquiries”."
							}
						/>
					</ListItem>
					<ListItem className="px-3 md:px-8">
						<FAQItem
							question={"How do I send a single machine quotation request?"}
							answer={
								"Hit the “Single machines” button present in the meniu, in the left side of your screen. This will take you to the 'New Quotation Request' page where you can add the desired equipments by hitting “+Add” button present in the top right side of the screen, select the products that will go through the equipment, enter additional information for the producer, and select the producer(s) you want to submit the request. . When you are ready, hit the “Request quotation” button present in the bottom right of the screen and your request will be send and will appear in the tables from “Inquiries”."
							}
						/>
					</ListItem>
					<ListItem className="px-3 md:px-8">
						<FAQItem
							question={"What should I do when the page freezes?"}
							answer={
								"In case the page on which you are on is frozen and doesn’t respond at any action, hit Ctrl + F5 keys on your keyboard for a hard refresh."
							}
						/>
					</ListItem>
					<ListItem className="px-3 md:px-8">
						<FAQItem
							question={"What type of support is provided by SiloApp?"}
							answer={
								"To assist in your ongoing success, SiloApp provides the following support services to our customers\n" +
								"•	Flexible support options – web, email, phone and chat.\n" +
								"•	A skilled, well trained team of support professionals available to answer your inquiries at any time.\n" +
								"•	The ability to submit an unlimited number of support tickets, at no additional cost.\n" +
								"•	Dedicated FAQ section. \n"
							}
						/>
					</ListItem>
					<ListItem className="px-3 md:px-8">
						<FAQItem
							question={"How can I reach the support team of SiloApp?"}
							answer={
								"You can contact the support team by multiple ways. One way would be to fill out the form at the bottom of the FAQ page. This way a ticket will be opened and we will get back to you asap. Another way would be by hitting the “Contact” button present in the app’s menu, where you will find an e-mail address where you can write to us and a phone number at which you can call for quick support."
							}
						/>
					</ListItem>
				</List>
				<Link
					to="/FAQ"
					onClick={scrollTop}
					className="mx-3 flex place-items-center justify-center gap-2 group hover:bg-gray-200 p-1 rounded-sm"
				>
					<span className="text-base py-1 font-bold group-hover:underline">
						View more
					</span>
					<img
						src={arrowright}
						alt=""
						className="w-3 h-3"
					></img>
				</Link>
			</Stack>
			<Stack className="min-w-full md:min-w-[270px] sm:max-w-[270px] md:max-w-[270px] lg:max-w-[420px] justify-center">
				<img
					src={directfaqsec}
					alt=""
					className="object-cover h-full"
				/>
			</Stack>
		</Container>
	);
};

export default DirectFAQ;
